import React, { useState } from "react";
import cx from "classnames";
import withModal from "../withModal";
import getLogger from "../../lib/debug-logger";

const log = getLogger("ModalIframe", 2);

const ModalPlaceholder = (props) => {
  const { width, height, loading } = props;
  const cls = !loading ? "image-missing" : "shimmer-gradient";
  return (
    <div
      style={{ position: loading ? "absolute" : "static", width, height }}
      className={cx("image-placeholder", cls)}
    >
      <p>loading...</p>
    </div>
  );
};

const ModalIframe = (props) => {
  const { src, width = 600, height = width, className, cleanup } = props;
  const [loading, setLoading] = useState(true);

  const stl = { width, height };
  const cls = cx("modal-box", className);

  const onLoadHandler = (ev) => {
    log("iframe has loaded, source URL", ev.target.src); // --------------------- log
    // even after onload is triggered map takes a while to show up
    setTimeout(() => setLoading(false), 3000);
  };

  return (
    <div className={cls}>
      <div className="modal-content">
        <div className="frame-wrap">
          {loading ? <ModalPlaceholder width={width} height={height} loading={loading} /> : null}
          <iframe
            {...stl}
            src={src}
            title="Interactive Frame"
            scrolling="no"
            onLoad={onLoadHandler}
          ></iframe>
        </div>
        <div className="bottom">
          <button className="btn-close" onClick={() => cleanup()}>
            {"Close"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default withModal(ModalIframe);
