import React from "react";
import { useGlobal } from "reactn";

import "../../styles/avm/spinner.scss";

const Spinner = () => {
  const [showSpinner] = useGlobal("BUSY");

  return (
    !!showSpinner && (
      <div className="spinner">
        <div className="spinner-ripple">
          <div></div>
          <div></div>
        </div>
      </div>
    )
  );
};

export default Spinner;
