import getLogger from "./debug-logger";

const log = getLogger("webapi", true);

// window, document and cross-frame utilities

export const getBodyClassSwappers = (addedBodyClass = "added") => {
  const addBodyClass = () => {
    if (!document.body.classList.contains(addedBodyClass)) {
      document.body.classList.add(addedBodyClass);
    }
  };
  const restoreBodyClass = () => {
    if (document.body.classList.contains(addedBodyClass)) {
      document.body.classList.remove(addedBodyClass);
    }
  };
  return [addBodyClass, restoreBodyClass];
};

export const isTopWindow = () => window.parent === window.top;

export const isFrame = () => !isTopWindow();

// returns first matched element or null (by CSS selectors, no pseudo-elements)
export const selectOne = (selectors) => document.querySelector(selectors);

// returns NodeList
export const selectAll = (selectors) => document.querySelectorAll(selectors);

// matched element or null
export const selectId = (id) => document.getElementById(id);

// returns HTMLCollection; multiple names separated by space
export const selectClass = (names) => document.getElementsByClassName(names);

// returns HTMLCollection
export const selectTag = (name) => document.getElementsByTagName(name);

// returns NodeList, selected by "name" attribute
export const selectName = (name) => document.getElementsByName(name);

// document height
export const getBodyHeight = () => document.body.offsetHeight;

// element has specified class (string) or one of classes (array)
export const elementHasClass = (el, name) => {
  if (typeof name === 'string') return el.classList.contains(name);
  if (Array.isArray(name)) {
    for (let n of name) {
      if (el.classList.contains(n)) return true;
    }
  }
  return false;
};

// element or parent has specified class
export const elementFromClass = (el, name) => {
  if (elementHasClass(el, name)) return true;
  const names = typeof name === 'string' && !!name ? [name] : Array.isArray(name) && name.length ? name : null;
  if (!names) return false;
  const parent = el.parentElement;
  if (!parent) return false;
  return elementFromClass(parent, names);
}

// send a message from iframe to parent window
export const sendToParent = (dataObj) => {
  if (isTopWindow()) return;
  log("sending message to parent", dataObj);
  window.parent.postMessage(dataObj, "*");
};

// report document height to parent window
export const reportHeightToParent = () => {
  sendToParent({ frameHeight: getBodyHeight() });
};

// handling iframe's document height change
export const handleFrameHeightChange = () => {
  if (isTopWindow()) return;
  log("adding height change handlers");
  window.addEventListener("load", reportHeightToParent);
  window.addEventListener("resize", reportHeightToParent);
};

// handling iframe height in parent window using postMessage
export const keepFrameHeight = (frameSelector, extraPadding = 0) => {
  const frame = selectOne(frameSelector);
  if (!frame) return;
  log("adding frame height change handler");
  const handler = (ev) => {
    if (ev.data.hasOwnProperty("frameHeight")) {
      log("handling frame change");
      frame.style.height = ev.data.frameHeight + extraPadding + "px";
    }
  };
  window.addEventListener("message", handler);
  const cleanup = () => {
    window.removeEventListener("message", handler);
  };
  return cleanup;
};
