// Products available for currently logged in user
// (provided by auth proxy lambda upon successful login)

import { getProp, hasProp, jsonParse, jsonStringify, listSize } from "./util";

export const extractProducts = (userInfoObject) => {
  const userInfo = userInfoObject;
  const prodArr = getProp(userInfo, "products");
  if (!listSize(prodArr)) {
    return null;
  }
  // combine products into one object
  let prodObj = Object.assign({}, ...prodArr);
  // stringify and remove all 'OSS.' prefixes, then restore the object
  prodObj = jsonParse(jsonStringify(prodObj).replace(/\bOSS\./g, ""));

  const prodConfig = {
    PRODUCTS_MAP: prodObj,
    // availability flags for optional products
    PROD_AVAIL_COMPARABLES: hasProp(prodObj, "MarketValueComparables"),
    PROD_AVAIL_RENTAL_VAL: hasProp(prodObj, "RentalValuation"),
    PROD_AVAIL_VALUATION: hasProp(prodObj, "ResidentialMarketValuation"),
    PROD_AVAIL_VALUE_RANGE: hasProp(prodObj, "EstimatedValueRange"),
    PROD_AVAIL_CONFIDENCE: hasProp(prodObj, "MarketValuationConfidence"),
    //
    PROD_AVAIL_LISTING: hasProp(prodObj, "PropertyListings"),
    PROD_AVAIL_SALES: hasProp(prodObj, "PropertySales"),
    PROD_AVAIL_ASSESSMENTS: hasProp(prodObj, "AssessmentListings"),
    PROD_AVAIL_RENTALS: hasProp(prodObj, "RentalListings"),
    PROD_AVAIL_HPI: hasProp(prodObj, "HPIHistorical"),
    PROD_AVAIL_PERMITS: hasProp(prodObj, "OptaPermitFinancial"),
    //
    PROD_AVAIL_STATS: hasProp(prodObj, "PropertyStats"),
    PROD_AVAIL_NEIGHBOURHOOD: hasProp(prodObj, "NeighbourhoodName"),
    PROD_AVAIL_METRICS: hasProp(prodObj, "MarketMetrics"),
    //
    PROD_AVAIL_SNAPSHOT: hasProp(prodObj, "iClarifySnapshotReport"),

    // Environmental
    PROD_AVAIL_FLOOD: hasProp(prodObj, "FloodScore"),
    PROD_AVAIL_WILDFIRE: hasProp(prodObj, "WildfireIndex"),
    PROD_AVAIL_FUS: hasProp(prodObj, "FUS"),

    PROD_AVAIL_ACTIVE_WILDFIRES: hasProp(prodObj, "ActiveWildfires"),
  };
  return prodConfig;
};
