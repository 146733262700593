// Custom client-side logger
import qs from "query-string";
import config from "../config";
import storage from "./localstorage";
import { isObject } from "./util";

const startTime = Date.now();

export const debugLevel = (() => {
  let level = 0;
  // check if debug level parameter exists in QS
  if (typeof window !== "undefined") {
    const qso = qs.parse(window.location.search, { parseNumbers: true });
    if ("dbg" in qso) {
      level = Number(qso.dbg);
    } else if (!level) {
      level = storage.get("dbg") || 0;
    }
    storage.set("dbg", level);
  }
  return Number(level || config.debugLevel) || 0;
})();

// pretty-formatting objects
const o2s = (x) => {
  if (isObject(x)) {
    try {
      return JSON.stringify(x, null, 2);
    } catch {}
    return x.toString();
  }
  return x + "";
};

// produce custom logging function
const getLogger = function (label, level = 1) {
  let debugOn = debugLevel === Number(level);

  const consoleLog = function (...args) {
    if (!debugOn || !args.length) {
      return;
    }
    let lines = args.map((x) => o2s(x));
    let first = lines[0];
    let sep = first.includes("\n") ? "\n" : " ";
    let secs = (Date.now() - startTime) / 1000;
    first = `${secs.toFixed(3)}|${label}:${sep}${first}`;

    console.log(first);

    let rest = lines.slice(1).join("\n");
    if (rest) console.log(rest);
  };
  return consoleLog;
};

export default getLogger;
