import React, { useCallback, useEffect } from "react";
import { useGlobal } from "reactn";
import cx from "classnames";
import { EN, FR, initLanguage } from "../useLanguage";
import { dropReport } from "../../lib/local-data";
import getLogger from "../../lib/debug-logger";

import "../../styles/avm/lang-switch.scss";

const log = getLogger("LangSwitch", 1);

const LangSwitch = () => {
  const [language] = useGlobal("LANGUAGE");

  useEffect(() => {
    if (!language) initLanguage();
  }, [language]);

  const toggleLang = useCallback(() => {
    const otherLang = language === EN ? FR : EN;
    log(`switching to '${otherLang}'`); //--------------- log
    initLanguage(otherLang);
    dropReport();
  }, [language]);

  return <span className={cx("language", language)} role="button" onClick={toggleLang}></span>;
};

export default LangSwitch;
