// persistent local storage with limited time to live

import storage from "local-storage";
import { EN, FR } from "../lib/globals";
import { getProp, hasProp, isNone, lowerCase } from "./util";

const MAX_AGE = 10 * 24 * 3600 * 1000; // should be greater than session time

const REFRESH_KEY = "______ts"; // storage initialization timestamp key
const EXP_KEY = "______xts"; // each record's expiry timestamp key
const LANGKEY = "__lang"; // storage key for default language
export const LOGINFAILKEY = "______tlf"; // track login failures

const preservedKeys = [LANGKEY, LOGINFAILKEY];

//------------------------------------------------------
// stored language operations
//------------------------------------------------------
const validateLang = (twoLetters) => {
  let lang = lowerCase(twoLetters);
  if (lang !== EN && lang !== FR) return EN;
  return lang;
};

const readStoredLang = () => storage.get(LANGKEY);

const saveStoredLang = (lang) => storage.set(LANGKEY, validateLang(lang));

export const checkLanguage = (newLang = null) => {
  let lang = validateLang(newLang || readStoredLang());
  saveStoredLang(lang);
  return lang;
};

//------------------------------------------------------
// check if entire storage content is expired
//------------------------------------------------------
const isStorageValid = () => {
  const ts = Number(storage.get(REFRESH_KEY)) || 0;
  return ts && ts + MAX_AGE > Date.now();
};

//------------------------------------------------------
// stored data records are optionally bundled with expiration time;
//------------------------------------------------------
const getItem = (key) => {
  const rec = storage.get(key);
  if (hasProp(rec, EXP_KEY)) {
    // expiration time is provided
    const expires = Number(rec[EXP_KEY]) || 0;
    if (expires > Date.now()) {
      // data is valid - return nested property under the same key
      return getProp(rec, key);
    }
    // invalidate data that has expired or has bad timestamp
    storage.remove(key);
    return storage.get(key); // whatever it is after removal
  }
  return rec;
};

//------------------------------------------------------
// set value, optionally combined with expiry timestamp;
// optional value lifespan (ttl) is in seconds
//------------------------------------------------------
const setItem = (key, value, ttl = 0) => {
  const ts = Date.now();
  storage.set(REFRESH_KEY, ts); // initialize if required
  const rec = ttl ? { [key]: value, [EXP_KEY]: ts + Number(ttl) } : value;
  return storage.set(key, rec);
};

//------------------------------------------------------
// read a record, remove it from storage and return
// (doesn't exist in underlying local-storage)
//------------------------------------------------------
const popItem = (key) => {
  const rec = getItem(key);
  if (!isNone(rec)) storage.remove(key);
  return rec;
};

//------------------------------------------------------
// clear storage, preserve language
//------------------------------------------------------
const clearAll = () => {
  const preservedItems = {};
  for (let k of preservedKeys) {
    let v = storage.get(k);
    if (!isNone(v)) preservedItems[k] = v;
  }
  storage.clear();
  for (let k in preservedItems) {
    storage.set(k, preservedItems[k]);
  }
};

//------------------------------------------------------
// exported object that mimics local-storage functionality
//------------------------------------------------------
const customStorage = {
  get: getItem,
  set: setItem,
  remove: (k) => {
    storage.remove(k);
  },
  clear: clearAll,
  pop: popItem, // added method
};

//------------------------------------------------------
// validate storage before using it
//------------------------------------------------------
if (!isStorageValid()) {
  clearAll();
}

export default customStorage;
