import axios from "axios";
import { setBusy } from "./global-util";
import getLogger from "./debug-logger";

const log = getLogger("api", 3);

//-----------------------------------------------------
// create custom HTTP client
//-----------------------------------------------------
export const getHttpClient = (timeout = 60000) => {
  const client = axios.create({ timeout });
  // show spinner before sending request
  client.interceptors.request.use((conf) => {
    log("client config", conf); //---------------------
    setBusy(true);
    return conf;
  });
  // hide spinner on successful response or error
  client.interceptors.response.use(
    (response) => {
      log("client response", response); //---------------------
      setBusy(false);
      return response;
    },
    (error) => {
      setBusy(false);
      Promise.reject(error);
    }
  );
  return client;
};

//-----------------------------------------------------
// General purpose API call
//-----------------------------------------------------
export const apiCall = async (config) => {
  const client = getHttpClient();
  // config options see here https://github.com/axios/axios
  try {
    const res = await client.request(config);
    if (!res) {
      throw new Error(`No response from ${config.url}`);
    }
    if (!res.data) {
      log("Bad response", res);
      throw new Error(res.status ? `${res.status} ${res.statusText}` : "No data");
    }
    log(`Data received from ${config.url}`, res.data);
    return res.data;
  } catch (x) {
    const msg = (typeof x === "object" && x.message) || x + "";
    log(msg);
  }
  return null;
};

export default apiCall;
