import React, { useCallback, useEffect, useState } from "react";
import { useGlobal } from "reactn";
import cx from "classnames";
import useLanguage from "../useLanguage";
import RecalcButton from "./RecalcButton";
import { formatDollars, isEmpty, isFunction, listSize, lowerCase } from "../../lib/util";
import { loadEstimatedValueRange, loadMarketValue, loadRentalValue } from "../../lib/oss-api";
import { updatePropertyInfo, updateStoredValue } from "../../lib/local-data";
import getLogger from "../../lib/debug-logger";

import "../../styles/avm/property-valuation.scss";

const log = getLogger("PropertyValuation", 1);

const formatRange = (pair) => {
  if (!listSize(pair)) return "";
  let values = pair
    .filter(Boolean)
    .map(Number)
    .sort((a, b) => (a < b ? -1 : a > b ? 1 : 0))
    .map(formatDollars);
  return listSize(values) ? values.join(" - ") : "";
};

const PropertyValuation = (props) => {
  const { isExplained, handleExplainability } = props; // Explainability handler

  const [hasValuation] = useGlobal("PROD_AVAIL_VALUATION");
  const [hasValueRange] = useGlobal("PROD_AVAIL_VALUE_RANGE");
  const [hasRental] = useGlobal("PROD_AVAIL_RENTAL_VAL"); // user has Rental product

  const [readonly] = useGlobal("READONLY_PREFILL");
  const [valueRangeRequested] = useGlobal("VALUE_RANGE_REQUESTED");
  const [revealValueRange] = useGlobal("REVEAL_EVR");
  const [revealAvm] = useGlobal("REVEAL_AVM");

  const [showRental] = useGlobal("SHOW_RENTAL"); // depends on province only
  const [rentalType] = useGlobal("RENTAL_TYPE");
  const [avmValue] = useGlobal("AVM_VALUE");
  const [confidence] = useGlobal("CONFIDENCE_RATE");
  const [lowValue] = useGlobal("AVM_RANGE_LOW");
  const [highValue] = useGlobal("AVM_RANGE_HIGH");
  const [rentalRange] = useGlobal("RENTAL_RANGE");
  const [dirtyKeys] = useGlobal("DIRTY_KEYS"); // updated features' keys

  // valuation errors
  const [valError] = useGlobal("AVM_ERROR");
  const [rangeError] = useGlobal("AVM_RANGE_ERROR");
  const [rentalError] = useGlobal("RENTAL_VALUE_ERROR");

  const [avmFailed] = useGlobal("AVM_FAILED"); // is set to true on any failed valuation call

  const [visible, setVisible] = useState(false);
  const [unsaved, setUnsaved] = useState(false);
  const [confidenceValue, setConfidenceValue] = useState("");

  const [explained, setExplained] = useState(false);


  const { t, language } = useLanguage();

  const isRentalVisible = useCallback(() => {
    return !readonly && hasRental && showRental;
  }, [readonly, hasRental, showRental]);

  // Explainability handle exists?
  useEffect(() => {
    setExplained(isExplained && isFunction(handleExplainability));
  }, [isExplained, handleExplainability, setExplained]);

  const flipExplained = useCallback(
    () => {
      if (isFunction(handleExplainability)) {

        handleExplainability();}
    },
    [handleExplainability]
  );

  // store updated valuation
  useEffect(() => {
    const info = {
      AVM_VALUE: avmValue,
      CONFIDENCE_RATE: confidence,
      AVM_RANGE_LOW: lowValue,
      AVM_RANGE_HIGH: highValue,
    };

    updatePropertyInfo(info);
  }, [avmValue, confidence, lowValue, highValue, valueRangeRequested, revealValueRange, revealAvm]);

  useEffect(() => {
    updateStoredValue("RENTAL_RANGE", rentalRange);
  }, [rentalRange]);

  useEffect(() => {
    // show confidence if any of valuation products were requested
    if (!confidence || (!avmValue && !highValue && !rentalRange)) setConfidenceValue(null);
    else setConfidenceValue(lowerCase(confidence));
  }, [confidence, avmValue, highValue, rentalRange, setConfidenceValue]);

  useEffect(() => {
    setVisible(hasValuation || hasValueRange || isRentalVisible());
  }, [hasValuation, hasValueRange, isRentalVisible, setVisible]);

  useEffect(() => {
    setUnsaved(!!listSize(dirtyKeys));
  }, [dirtyKeys, setUnsaved]);

  const handleRentalClick = (ev) => {
    if (rentalType) {
      log(`triggering rental value call`); //------------------ log
      loadRentalValue(rentalType);
    }
  };

  const handleRangeClick = useCallback(
    (ev) => {
      if (!highValue && !lowValue) {
        log(`triggering value range call`); //------------------ log
        loadEstimatedValueRange();
      }
    },
    [lowValue, highValue]
  );

  const handleValueClick = useCallback(
    (ev) => {
      if (!avmValue) {
        log(`triggering AVM call`); //------------------ log
        loadMarketValue();
      }
    },
    [avmValue]
  );

  //---------------------------
  if (!visible) return null;
  //---------------------------

  //---------------------------
  // if any valuation call failed and none of valuation points successfully retrieved,
  // consider AVM is not available for this property
  if (avmFailed && !avmValue && !rentalRange && !lowValue && !highValue) {
    return (
      <section className="property-valuation avm-failed">
        <h4 className={cx("add-colon", language)}>{t("Valuation Information")}</h4>
        <div className="error-text">{t("AVM_FAILED")}</div>
      </section>
    );
  }
  //---------------------------

  return (
    <section className="property-valuation">
      <div className="property-valuation-top">
        <div
          className={cx("property-valuation-label h4", language, { "add-colon": !!hasValuation, explained })}
        >
          {t("Valuation Information")}
          {explained && (
            <div
              className="explained-handle"
              onMouseEnter={() => flipExplained(true)}
            ></div>
          )}
        </div>
        {!!hasValuation && !!confidenceValue && !valError && (
          <div className={cx("property-valuation-confidence-box", language)}>
            {!!confidenceValue && [
              <div className="confidence-title">{t("Confidence")}</div>,
              <div className={cx("confidence-value", confidenceValue)}>{t(confidenceValue)}</div>,
            ]}
          </div>
        )}
      </div>
      {!!valError && <div className="error-text">{t(valError)}</div>}
      {!!rangeError && <div className="error-text">{t(rangeError)}</div>}
      {!!rentalError && <div className="error-text">{t(rentalError)}</div>}
      <div className="property-valuation-main table">
        <div className="property-valuation-header tr">
          {hasValueRange && (
            <div className="property-valuation-range th">
              <button onClick={handleRangeClick} disabled={!!lowValue || unsaved}>
                {t("Estimated Value Range")}
              </button>
            </div>
          )}
          {hasValuation && (
            <div className="property-valuation-valuation th">
              <button onClick={handleValueClick} disabled={!!avmValue || unsaved}>
                {t("Valuation (AVM)")}
              </button>
            </div>
          )}
          {isRentalVisible() && (
            <div className="property-valuation-rental th">
              <button onClick={handleRentalClick} disabled={!rentalType || !isEmpty(rentalRange)}>
                {t("Estimated Rent (per month)")}
              </button>
            </div>
          )}
        </div>
        <div className="property-valuation-values tr">
          {hasValueRange && (
            <div className="td property-valuation-range">
              {((valueRangeRequested || revealValueRange) && formatRange([lowValue, highValue])) ||
                " "}
            </div>
          )}
          {hasValuation && (
            <div className="td property-valuation-valuation">
              {formatDollars(avmValue, true) || " "}
            </div>
          )}
          {isRentalVisible() && (
            <div className={cx("td property-valuation-rental", { tip: !rentalType })}>
              {formatRange(rentalRange) || (!rentalType && t("Select Rental Type"))}
            </div>
          )}
        </div>
      </div>

      <div className="recalc-panel">
        <RecalcButton />
      </div>
    </section>
  );
};

export default PropertyValuation;
