// Legal Land Lot data for 3 provinces, where it's applicable
// Refer to http://bitbucket.sharedsvcs.optaservice.com:7990/projects/CVF/repos/iclarifyresidentialgui/browse/app/javascript/search.js#1642

import { digObject, getProp, isArray, range, strlen, strval } from "./util";

import getLogger from "./debug-logger";

const log = getLogger("legal-land-lot-data", 3);

//------------------------------------------------------------
// helper functions
//------------------------------------------------------------
// adding leading zeroes to array of numbers, or strings, starting with numbers
const prefZeroes = (n, size) => {
  let s = strval(n),
    num = s.replace(/\D+$/, ""),
    trail = s.replace(/^\d+/, "");
  if (!num.length) return s;
  while (num.length < size) num = "0" + num;
  return `${num}${trail}`;
};

const pref0 = (n) => prefZeroes(n, 2);

const pref00 = (n) => prefZeroes(n, 3);

const unpref0 = (s) => strval(s).replace(/^0+/, "");

// Comparison function for sorting numeric strings with optional non-numeric suffix
// prettier-ignore
const comparer = (strNum1, strNum2) => {
  const [n1, n2, len1, len2] = [parseInt(strNum1), parseInt(strNum2), strlen(strNum1), strlen(strNum2)];
  return n1 < n2 ? -1 : n1 > n2 ? 1 : len1 < len2 ? -1 : 0;
};

//------------------------------------------------------------
// Building data container by provinces and legal address entries
//------------------------------------------------------------
const o = (() => {
  const obj = { AB: {}, MB: {}, SK: {} };
  for (let prov in obj) {
    for (let what of ["quarter", "section", "township", "range", "meridian"]) {
      obj[prov][what] = {};
    }
  }
  return obj;
})();

//------------------------------------------------------------
// Quarters
//------------------------------------------------------------

// options - same for all 3 provinces
// prettier-ignore
o.AB.quarter.options = ["NW 1/4", "NE 1/4", "SW 1/4", "SE 1/4", "N 1/2", "S 1/2", "W 1/2", "E 1/2", "LSD 1", "LSD 2", "LSD 3", "LSD 4", "LSD 5", "LSD 6", "LSD 7", "LSD 8", "LSD 9", "LSD 10", "LSD 11", "LSD 12", "LSD 13", "LSD 14", "LSD 15", "LSD 16", ];

o.MB.quarter.options = [...o.AB.quarter.options];

o.SK.quarter.options = [...o.AB.quarter.options];

// option values
o.AB.quarter.values = ["NW", "NE", "SW", "SE", "N", "S", "W", "E", ...range(1, 16)].map(pref0);

o.MB.quarter.values = [...o.AB.quarter.values];

// for SK Quarter values strip leading zeroes
o.SK.quarter.values = o.AB.quarter.values.map(unpref0);

//------------------------------------------------------------
// Sections
//------------------------------------------------------------
// option labels
o.AB.section.options = [...range(1, 36)].map(strval);

o.MB.section.options = [...o.AB.section.options];

// SK has some A-suffixed entries
o.SK.section.options = [...range(1, 36), "6A", "7A", "18A", "19A", "31A", "36A"]
  .map(strval)
  .sort(comparer);

// option values
o.AB.section.values = o.AB.section.options.map(pref0); // add leading 0s

o.MB.section.values = [...o.AB.section.values];

o.SK.section.values = o.SK.section.options.map(pref0); // add leading 0s

//------------------------------------------------------------
// Townships
//------------------------------------------------------------

// option labels
o.AB.township.options = [...range(1, 127)].map(strval);

o.MB.township.options = [...o.AB.township.options];

// SK has some A-suffixed entries
o.SK.township.options = [...range(1, 127), "19A", "27A", "42A", "43A", "45A", "46A", "47A"]
  .map(strval)
  .sort(comparer);

// option values (add up to 2 leading 0s)

o.AB.township.values = o.AB.township.options.map(pref00);

o.MB.township.values = [...o.AB.township.values];

o.SK.township.values = o.SK.township.options.map(pref00);

//------------------------------------------------------------
// Ranges
//------------------------------------------------------------
// AB
o.AB.range.options = [...range(1, 30)].map(strval);
o.AB.range.values = o.AB.range.options.map(pref0);

// MB
o.MB.range.options = [...range(1, 32)].map(strval);
o.MB.range.values = o.MB.range.options.map(pref0);

// SK has some A-suffixed entries
o.SK.range.options = [...range(1, 34), "13A", "21A", "24A"].map(strval).sort(comparer);
o.SK.range.values = o.SK.range.options.map(pref0);

//------------------------------------------------------------
// Meridians
//------------------------------------------------------------
// AB
o.AB.meridian.options = ["W4", "W5", "W6"];
o.AB.meridian.values = [...o.AB.meridian.options];

// MB
o.MB.meridian.options = ["E1", "E2", "W1"];
o.MB.meridian.values = [...o.MB.meridian.options];

// SK
o.SK.meridian.options = ["W1", "W2", "W3"];
o.SK.meridian.values = [...o.SK.meridian.options];

//------------------------------------------------------------
// lookup option by value
//------------------------------------------------------------
const getOptionByValue = (prov, what, value) => {
  const options = digObject(o, prov, what, "options");
  const values = digObject(o, prov, what, "values");
  if (!isArray(options) || !isArray(values)) return value;
  const index = values.indexOf(value);
  return (index >= 0 && options[index]) || value;
};

//------------------------------------------------------------
// Format legal address as string:
// Format will be the values for Quater/LSD, Section, Township, Range, Meridian
// separated by a space and dash then followed by a comma and province code.
// Example: NW 1/4 - 2 - 3 - 4 - W4 ,AB
//------------------------------------------------------------
export const formatLegalAddress = (obj) => {
  const prov = getProp(obj, "Province");
  const legal = getProp(obj, "LegalDescription");
  const keys = ["Quarter", "Section", "Township", "Range", "Meridian"];
  const options = keys.map((key) => {
    let val = getProp(legal, key, "?");
    let k = key.toLocaleLowerCase();
    return getOptionByValue(prov, k, val);
  });
  return options.join(' - ') + ', ' + prov;
};

//------------------------------------------------------------
log(o); //----------------- log

export default o;
