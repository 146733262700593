import React, { useState, useEffect } from "react";
import { useGlobal } from "reactn";
import useLanguage from "../useLanguage";
import { isEmpty } from "../../lib/util";
import { removePropertyInfo } from "../../lib/local-data";
import { goHome } from "../../lib/session";

const triggerNewSearch = () => {
  // remove current property from local storage
  removePropertyInfo();
  // navigate to Property Search page
  goHome();
};

const NewSearchButton = (props) => {
  const [featList] = useGlobal("DIRTY_KEYS");
  const [disabled, setDisabled] = useState(false);
  const { t } = useLanguage();

  useEffect(() => {
    const b = !isEmpty(featList);
    setDisabled(b);
  }, [featList, setDisabled]);

  return (
    <button
      className="big-button bg-icon-search-2"
      onClick={triggerNewSearch}
      disabled={disabled}
    >
      {t("New Search")}
    </button>
  );
};

export default NewSearchButton;
