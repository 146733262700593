import React, { useCallback, useState } from "react";
import cx from "classnames";
import useLanguage from "../useLanguage";

import "../../styles/avm/verisk-banner.scss";

//const bannerPicEn = "/Banner_AVM.jpg";
//const bannerPicFr = "/Banner_AVM-FR.jpg";

const infoTitles = {
  en: `Opta becomes Verisk, January 1, 2025.`,
  fr: `Le 1er janvier 2025, Opta devient Verisk.`,
};

const infoUrl =
  "https://www.verisk.com/company/newsroom/verisk-acquires-opta-canadas-leading-provider-of-property-intelligence-and-technology-solutions/";

const infoHtml = {
  en: `<p>Our future is brighter and more innovative than ever as Verisk Canada. Following Veriskʹs
<a href="${infoUrl}" target="_blank" rel="noopener noreferrer">acquisition of Opta in 2022</a>, our integration with Verisk will be complete by January 1, 2025.
The Verisk logo and visual identity will mark all of our products and solutions, signifying that
weʹre now part of a global source of data, analytics, and technology trusted by insurers
around the world.</p>

<p>Weʹll stay connected to our Canadian roots, committed to our long-standing customer relationships, and mindful of the trust weʹve earned across the property/casualty insurance
and financial services industries. At the same time, weʹre already drawing on Veriskʹs deep resources to help our clients sharpen their competitive edge. Since joining Verisk in 2022, weʹve combined the superior data sets and leading-edge technologies of both organizations
to deliver new and innovative solutions at scale, backed by the industryʹs highest standards
for data quality, security, and privacy. Weʹre just getting started. Watch for more news on our
integration and reach out to your regular contacts to learn more.</p>`,

  fr: `<p>Dans le cadre de Verisk Canada, nous sommes destinés à un avenir étincelant et plus
innovant que jamais. Suite à <a href="${infoUrl}" target="_blank" rel="noopener noreferrer">l'acquisition d'Opta par Verisk en 2022</a>, notre intégration avec Verisk sera complétée d'ici le 1er janvier 2025. Le logo et l'identité visuelle de Verisk
marqueront tous nos produits et nos solutions, ce qui signifie que nous faisons désormais
partie d'une source mondiale de données, d'analyses et de technologies fiables reconnues
par les sociétés dʹassurance du monde entier.</p>

<p>Par ailleurs, nous restons connectés à nos racines canadiennes, engagés dans nos relations
de longue date avec nos clients et conscients de la confiance que nous avons gagnée dans
les secteurs de l'assurance de dommages et des services financiers. En parallèle, nous
puisons déjà dans les immenses ressources de Verisk pour appuyer notre clientèle à
améliorer ses avantages concurrentiels. Depuis que nous avons rejoint Verisk en 2022, nous
avons combiné les vastes bases de données et technologies de pointe des deux organismes
pour fournir des solutions nouvelles et innovantes, soutenues par les normes les plus élevées
du secteur en matière de qualité, de sécurité et de confidentialité des données. Nous ne
faisons que commencer. Nous vous invitons à surveiller plus de nouvelles sur notre
intégration et nʹhésitez pas de communiquer avec vos contacts habituels pour en savoir plus.</p>`,
};

const VeriskBanner = () => {
  const { t, language } = useLanguage();

  const [showPopup, setShowPopup] = useState(false);

  const popupOn = useCallback(() => {
    if (!showPopup) setShowPopup(true);
  }, [showPopup, setShowPopup]);

  const popupOff = useCallback(() => {
    if (showPopup) setShowPopup(false);
  }, [showPopup, setShowPopup]);

  if (!language) return null;

  return (
    <div className="verisk-banner-wrap">
      <div className={cx("verisk-banner", language)} onClick={popupOn}>
        {showPopup && (
          <div className="verisk-info-body">
            <h6>{infoTitles[language]}</h6>
            <div
              className="verisk-info-text"
              dangerouslySetInnerHTML={{ __html: infoHtml[language] }}
            ></div>
            <div className="info-bottom text-right">
              <button className="big-button" onClick={popupOff}>
                {t("Close")}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default VeriskBanner;
