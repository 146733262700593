import React, { useEffect } from "react";
import { useGlobal } from "reactn";
import config from "../../config";
import useLanguage from "../useLanguage";
import PropertyImage from "./PropertyImage";
import storage from "../../lib/localstorage";

import "../../styles/avm/property-images.scss";

const { googleMapEndpoint, googleMapKey } = config;

const PropertyImages = () => {
  const [streetUrl] = useGlobal("IMAGE_STREET");
  const [mapUrl] = useGlobal("IMAGE_MAP");
  const [address] = useGlobal("ADDRESS_LINE");
  const [geocode] = useGlobal("GEOCODE");
  const [dataError] = useGlobal("IMAGERY_ERROR");

  const { language, t } = useLanguage();

  useEffect(() => {
    if (address && geocode && language) {
      storage.set("mapIframeConfig", {
        address,
        geocode,
        googleMapKey,
        language,
      });
    } else {
      storage.remove("mapIframeConfig");
    }
  }, [address, geocode, language]);

  return (
    <div className="property-images">
      <figure className="image-box">
        <PropertyImage type="street" src={streetUrl} />
      </figure>
      <figure className="image-box">
        <PropertyImage type="map" src={mapUrl} iframeUrl={googleMapEndpoint} />
      </figure>

      {!!dataError && !streetUrl && !mapUrl && <div className="error-text">{t("IMAGERY_ERROR")}</div>}
    </div>
  );
};

export default PropertyImages;
