import React, { Fragment, useState, useEffect } from "react";
import { getBrandingInfo } from "../../lib/global-util";
import { getProp } from "../../lib/util";
import config from "../../config";
import SearchWidget from "./SearchWidget";
import withSession from "../withSession";
import useLanguage from "../useLanguage";

import "../../styles/avm/search-view.scss";

const getLogoUrl = (fname) => (fname && config.assetsBase + fname) || "";

const SearchView = (props) => {
  const { loggedIn } = props;

  const [brandLogoStyle, setBrandLogoStyle] = useState(null);
  const { t } = useLanguage();

  useEffect(() => {
    if (loggedIn) {
      const brand = getBrandingInfo();
      const logoUrl = getLogoUrl(getProp(brand, "brand_logo_image", ""));
      if (logoUrl) {
        const style = { backgroundImage: `url('${logoUrl}')` };
        setBrandLogoStyle(style);
      }
    }
  }, [loggedIn]);

  return (
    <Fragment>
      <div className="search-wrap">
        {!!brandLogoStyle && <div className="brand-logo" style={brandLogoStyle}></div>}
        <div className="search-box">
          <h2 className="intro-title">{t("Property Search")}</h2>
          <SearchWidget />
        </div>
      </div>
    </Fragment>
  );
};

export default withSession(SearchView);
