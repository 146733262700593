import React, { useState, useEffect, useRef } from "react";
import "../styles/avm/modal.scss";

let zIndex = 1111;

const withModal = (WrappedComponent) => {
  return (props) => {
    const { cleanup, visible: show = true } = props;
    const [visible, setVisible] = useState(true);

    const modalEl = useRef(null);

    const hide = () => {
      typeof cleanup === "function" && cleanup();
      setVisible(false);
    };

    const handleOutClick = (ev) => {
      ev.target === modalEl.current && hide();
    };

    useEffect(() => {
      zIndex++;
      return () => {
        zIndex--;
      };
    });

    useEffect(() => {
      setVisible(!!show);
    }, [show]);

    if (!visible) return null;

    const style = { zIndex };

    return (
      <div ref={modalEl} className="modal" style={style} onClick={handleOutClick}>
        <WrappedComponent {...props} />
      </div>
    );
  };
};

export default withModal;
