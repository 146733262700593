import {
  getProp,
  isObject,
  makeNumericId,
  joinWords,
  strlen,
  isEmpty,
  isArray,
  makeShortDateStamp,
} from "./util";
import { isFrench, getUser } from "./global-util";
import getLogger from "./debug-logger";

const log = getLogger("oss-util", 3);

//------------------------------------------------------------
// produce street address as string from Address object
//------------------------------------------------------------
export const makeAddrStreet = (obj) => {
  const streetName = getProp(obj, "StreetName");
  if (!streetName) return ""; // wrong object supplied
  let str = joinWords("-", getProp(obj, "UnitNumber"), getProp(obj, "StreetNumber"));
  str = joinWords(
    " ",
    str,
    streetName,
    getProp(obj, "StreetType"),
    getProp(obj, "StreetDirection")
  );
  return str;
};

//------------------------------------------------------------
// produce city part of address as string from Address object
//------------------------------------------------------------
export const makeAddrCity = (obj) => {
  const town = getProp(obj, "Municipality");
  if (!town) return "";
  let str = joinWords(", ", getProp(obj, "Municipality"), getProp(obj, "Province"));
  str = joinWords(" ", str, getProp(obj, "PostalCode")).trim();
  return str;
};

//------------------------------------------------------------
// produce street and municipality parts from Address object or from pair of street and city parts
//------------------------------------------------------------
export const makeAddressLine = (addrArrObj) => {
  const streetCity = isArray(addrArrObj)
    ? [...addrArrObj]
    : isObject(addrArrObj)
    ? [makeAddrStreet(addrArrObj), makeAddrCity(addrArrObj)]
    : [];
  return streetCity.filter(Boolean).join(", ");
};

//------------------------------------------------------------
// Common OSS request header
//------------------------------------------------------------
export const buildRequestHeader = () => {
  return {
    RequestID: makeNumericId(),
    Requestor: {
      Username: getUser(),
    },
    Language: isFrench() ? "F" : "E",
  };
};

//------------------------------------------------------------
// property data request
//------------------------------------------------------------
export const buildPropertyPostData = (address, products, payload = {}) => {
  // address can be supplied as street address string or structured data object
  const addrObj = strlen(address) ? { StreetAddress: address } : isObject(address) ? address : null;
  if (isEmpty(addrObj)) {
    log(`no address in POST data`); //--------------------- log
    return null;
  }
  let propertyObj = { Address: addrObj, HistoricalDate: makeShortDateStamp(), ...payload };
  return {
    RequestHeader: buildRequestHeader(),
    RequestBody: {
      Property: [propertyObj],
      RequestedProducts: {
        Product: [...products], // must be array
      },
    },
  };
};
