// Construction Features: mapping sources from the product IClarifyFinancialResidentialV2

import { upperCase } from "./util";

const residentialMap = {
  USER: "U",
  AVRBC: "D",
  BGN: "B",
  VAL: "V",
  WIES: "W",
  PV: "T",
  BARONSLIST: "O",
  GROUNDTRUTHUPDATES: "L",
  BCMPAC: "J",
  BCA: "S",
  JLR: "R",
  WINN: "Y",
  MPAC: "H",
  MPACCACHE: "H",
  MPACLIVE: "M",
  NS: "N",
  BF: "F",
  CENT: "C",
  CQ: "Q",
  APPLIED: "Q",
  ESTIMATEDUPDATES: "K",
  BIF: "I",
  PREDICTED: "A",
  EST: "E",
  PERMITDEMO: "P",
  NA: "",
  NONE: "",
  ARCGIS: "0",
  THINKDATAWROKS: "1",
  BUILDINGFOOTPRINT: "2",
  GENWORTH: "3",
  FINTECHRESOVERLORD: "4",
  FINTECHCONDOOVERLORD: "5",
  GISLOTSIZE: "6",
  MODELEST: "7",
  IGUIDE: "8",
  FINTECHUSEREDITS: "9",
};

// condo source mapping extends/updates residential one
const condoMap = {
  ...residentialMap,
  USER: "CU",
  FINTECHCONDOOVERLORD: "C5",
  BCACONDO: "CS",
  MPACAPARTMENT: "CA",
  CITYOFWINNIPEGCONDO: "CY",
  JLRCONDO: "CR",
  ABEDMONTON: "CB",
  ABCALGARY: "CC",
  PVCONDO: "CT",
  STATICMPAC: "CH",
  MPACCONDO: "CM",
  BROOKFIELDCONDO: "CF",
  GENWORTH: "C3",
  FINTECHUSEREDITS: "C9",
  BARONSLIST: "CO",
  THINKDATAWORKS: "C1",
  EDMCONDO: "CD",
  BIF: "CI",
  EST: "CE",
};

export const getPolMarker = (modelKey, isCondo = false) => {
  const codeMap = isCondo ? { ...condoMap } : { ...residentialMap };
  const k = upperCase(modelKey);
  return k in codeMap ? codeMap[k] : modelKey;
};

// translate construction feats keys OSB/OSS (both Res & Condo)

export const translateFeatKey = (osbKey, isCondo = false) => {
  const osb2oss = {
    KitchenCountType: "KitchenCount",
    BathroomCountType: isCondo ? "NumberOfBathrooms" : "BathroomCount",
    StoreyCountType: "StoreyCount",
    Garage: "GarageType",
    Plumbing: "PlumbingType",
    AuxiliaryHeating: "AuxiliaryHeatingType",
    PresenceOfAnOutbuilding: "OutbuildingPresent",
    SewageService: "SewageType",
    WaterService: "WaterType",
    NumberOfBedroom: "NumberOfBedrooms",
    NumberOfDen: "NumberOfDens",
    NumberOfCars: "GarageNumberOfCars",
    WaterServiceType: "WaterType",
    SewageServiceType: "SewageType",
  };

  return osbKey in osb2oss ? osb2oss[osbKey] : osbKey;
};
