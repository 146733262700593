import React, { useState, useEffect } from "react";
import { useGlobal } from "reactn";
import cx from "classnames";

import ModalIframe from "./ModalIframe";
import ModalStreetview from "./ModalStreetview";
import ImagePlaceholder from "./PropertyImagePlaceholder";

import getLogger, { debugLevel } from "../../lib/debug-logger";

const log = getLogger("PropertyImage", 3);

const [width, height] = [610, 460];

// static map image size: 512x512;
// street view: 521x384;

const makeDbgUrl = (url) => {
  if (!debugLevel) return url;
  const dbg = `dbg=${debugLevel}`;
  const sep = url.includes("?") ? "&" : "?";
  return `${url}${sep}${dbg}`;
};

const PropertyImage = (props) => {
  const { type: imageType, src: dataSrc, alt: altText = "", iframeUrl = "" } = props;

  const [initCallTime] = useGlobal("PROPERTY_TIME"); // is set when initial data call is complete
  const [dataError] = useGlobal("IMAGERY_ERROR"); // don't show shimmer on error
  const [dataLoaded] = useGlobal("IMAGERY_LOADED");
  const [pageName] = useGlobal("PAGE_NAME");

  // in full mode modal components are added (main Property Details page only)
  const [fullMode, setFullMode] = useState(false);

  const [isMap, setIsMap] = useState(false);
  const [isStreetView, setIsStreetView] = useState(false);
  const [hasActiveMap, setHasActiveMap] = useState(false);

  const [imgSrc, setImgSrc] = useState("");

  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [imageError, setImageError] = useState(false);

  useEffect(() => {
    setFullMode(pageName === "main");
  }, [pageName]);

  useEffect(() => {
    setIsMap(imageType === "map");
    setIsStreetView(imageType === "street");
  }, [imageType]);

  useEffect(() => {
    setHasActiveMap(isMap && !!iframeUrl && fullMode);
  }, [isMap, iframeUrl, fullMode]);

  useEffect(() => {
    if (!dataLoaded) return;
    // abort loading state if loading times out
    setTimeout(() => {
      setLoading(false);
    }, 9999);
  }, [dataLoaded]);

  useEffect(() => {
    if (dataError || imageError || imgSrc || !!initCallTime) {
      // use timeout to avoid rendering oddities
      setTimeout(() => {
        setLoading(false);
      }, 99);
    }
  }, [dataError, imgSrc, initCallTime, imageError]);

  useEffect(() => {
    if (!dataSrc || imgSrc) return;
    log(`image source: '${dataSrc}'`);
    // preload image to catch errors
    const img = new Image();
    img.src = dataSrc;
    img.onload = function () {
      setImgSrc(dataSrc);
    };
    img.onerror = function () {
      setImageError(true);
    };
  }, [dataSrc, imgSrc]);

  const handleImageClick = () => {
    fullMode && !showModal && setShowModal(true);
  };

  const hideModal = () => {
    showModal && setShowModal(false);
  };

  if (!imgSrc) {
    return <ImagePlaceholder isMap={isMap} loading={loading} error={imageError || dataError} />;
  }

  return (
    <React.Fragment>
      <img
        className={cx("property-image", props.type && `image-${props.type}`, {
          linked: fullMode && (isStreetView || hasActiveMap),
        })}
        src={imgSrc}
        alt={altText}
        onClick={handleImageClick}
      />
      {fullMode && hasActiveMap && showModal && (
        <ModalIframe
          width={width}
          height={height}
          src={makeDbgUrl(iframeUrl)}
          cleanup={hideModal}
        />
      )}
      {fullMode && isStreetView && showModal && <ModalStreetview cleanup={hideModal} />}
    </React.Fragment>
  );
};

export default PropertyImage;
