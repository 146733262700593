import { arrayUnique, getProp, hasProp, inArray, isEmptyObject } from "./util";

const MIN_YEAR_BUILT = 1750;
const CURRENT_YEAR = new Date().getUTCFullYear();

export const Condominium = "Condominium";
export const Residential = "Residential";

//----------------------------------------------------------
// Identifying property types
//----------------------------------------------------------
export const isCondo = (propType) => propType === Condominium;
export const isResidential = (propType) => propType === Residential;

const residentialFeatures = [
  "YearBuilt",
  "SquareFootage",
  "FoundationType",
  "ArchitecturalStyleType",
  "StoreyCount",
  "KitchenCount",
  "NumberOfBedrooms",
  "BathroomCount",
  "ExteriorWallType",
  "RoofType",
  "FinishedBasement",
  "GarageType",
  "GarageNumberOfCars",
  "SwimmingPoolType",
  "PrimaryHeatingType",
  "WaterType",
  "SewageType",
  "LotSize",
  "LotFrontage",
  "LotDepth",
  "OutbuildingPresent",
  "CommercialIndicator",
  "Waterfront",
  "View",
];

export const getFeatsStatsKeys = (propType = "") => {
  return isCondo(propType) ? [] : ["PropertyOccupancy", "Tenure", "ZoningDescription"];
};

//----------------------------------------------------------
// Producing list of Residential Construction Features
// (2 subsets define rendering order for 2-column layout)
//----------------------------------------------------------
export const getResidentialFeaturesByColumns = () => [
  // Column 1
  [
    "YearBuilt",
    "SquareFootage",
    "FoundationType",
    "ArchitecturalStyleType",
    "StoreyCount",
    "KitchenCount",
    "NumberOfBedrooms",
    "BathroomCount",
    "ExteriorWallType",
    "RoofType",
    "FinishedBasement",
    "GarageType",
    "GarageNumberOfCars",
  ],
  // Column 2
  [
    "SwimmingPoolType",
    "PrimaryHeatingType",
    "WaterType",
    "SewageType",
    "LotSize",
    "LotFrontage",
    "LotDepth",
    "OutbuildingPresent",
    "CommercialIndicator",
    "Waterfront",
    "View",
    // added Property Stats follow
    ...getFeatsStatsKeys(),
  ],
];

//----------------------------------------------------------
// Fix integer data
//----------------------------------------------------------
const intTypeKeys = [
  "YearBuilt",
  "SquareFootage",
  "NumberOfBedrooms",
  "LotSize",
  "TotalFloorArea",
  "NumberOfDens",
  "NumberOfStoreys",
];

const fixType = (key, val) => (intTypeKeys.includes(key) ? Number(val) || 0 : val);

//----------------------------------------------------------
// Produce list of residential construction features keys
//----------------------------------------------------------
const getResidentialFeaturesKeys = () => {
  return [...residentialFeatures];
};

//----------------------------------------------------------
// Producing list of Condo Unit Construction Features
// (2 subsets for 2-column layout)
//----------------------------------------------------------
export const getCondoUnitFeaturesByColumns = () => [
  ["NumberOfBedrooms", "NumberOfBathrooms", "NumberOfDens", "Balcony", "FloorLevel"],
  ["TotalFloorArea", "GarageNumberOfCars", "Locker", "View"],
];

//----------------------------------------------------------
// Producing list of Condo Building Construction Features
// (2 subsets for 2-column layout)
//----------------------------------------------------------
export const getCondoBuildingFeaturesByColumns = () => [
  ["YearBuilt", "MultiResidentialStyleType", "NumberOfStoreys", "WaterType", "SewageType"],
  ["ParkingType", "ExteriorWallType", "RoofSurface", "PlumbingType", "HeatingType"],
];

//----------------------------------------------------------
// Producing 2 lists of condo construction features keys,
// for unit and building respectively
//----------------------------------------------------------
const getCondoFeaturesKeysObj = () => {
  const [a1, a2] = getCondoUnitFeaturesByColumns();
  const [a3, a4] = getCondoBuildingFeaturesByColumns();
  return {
    unit: [...a1, ...a2],
    building: [...a3, ...a4],
  };
};

//----------------------------------------------------------
// list all construction features keys,
// optionally, only those related to given property type
//----------------------------------------------------------
export const getConstructionFeaturesKeys = (propType = "") => {
  const resFeatKeys = getResidentialFeaturesKeys();
  if (isResidential(propType)) {
    return resFeatKeys;
  }
  const { unit, building } = getCondoFeaturesKeysObj();

  const condoFeatKeys = [...unit, ...building];
  if (isCondo(propType)) {
    return condoFeatKeys;
  }
  return arrayUnique([...resFeatKeys, ...condoFeatKeys]);
};

//----------------------------------------------------------
// initial configuration for construction features: will be extended from data schema;
// 'numeric' flag is used by text inputs only, indicating numeric strings;
// if 'dataType' is not specified, it will be inferred from actual data;
// dataType === 'number' will act as numeric === true;
//----------------------------------------------------------
export const initFeaturesConfig = (propType) => {
  const featDataKeys = getConstructionFeaturesKeys(propType);
  const featConfig = {};
  featDataKeys.forEach((featKey) => {
    let feat = {};
    if (featKey === "YearBuilt") {
      feat.minValue = MIN_YEAR_BUILT;
      feat.maxValue = CURRENT_YEAR;
      feat.maxLength = 4;
      feat.dataType = "number";
    } else if (featKey === "SquareFootage" || featKey === "TotalFloorArea") {
      feat.numeric = true;
      feat.maxLength = 5;
    } else if (featKey === "NumberOfBedrooms") {
      feat.numeric = true;
      feat.maxLength = 1;
    } else if (["LotSize", "LotDepth", "LotFrontage"].includes(featKey)) {
      feat.numeric = true;
    } else if (featKey === "FloorLevel" || featKey === "NumberOfStoreys") {
      feat.numeric = true;
      feat.maxLength = 3;
    } else if (featKey === "NumberOfDens") {
      feat.numeric = true;
      feat.maxLength = 1;
    }
    featConfig[featKey] = feat;
  });
  return featConfig;
};

// build updated features' data object accordingly with OSS/OSB format
export const transformUpdatedFeatures = (currentObj, refObj, propType, missingDataKeys) => {
  const extract = (keylist) => {
    const collected = {};
    for (let k of keylist) {
      let orig = getProp(refObj, k);
      let current = getProp(currentObj, k);
      // include actually changed construction features only
      if (current !== orig && (!!current || !inArray(missingDataKeys, k)))
        collected[k] = fixType(k, current);
    }
    // hardwire GarageType width GarageNumberOfCars in Residential
    if (
      isResidential(propType) &&
      ("GarageType" in collected || "GarageNumberOfCars" in collected)
    ) {
      collected.GarageType = getProp(currentObj, "GarageType");
      collected.GarageNumberOfCars = getProp(currentObj, "GarageNumberOfCars") || 0;
    }
    //
    return isEmptyObject(collected) ? null : collected;
  };
  let feats = null;
  if (isCondo(propType)) {
    const { unit, building } = getCondoFeaturesKeysObj();
    const unitFeats = extract(unit);
    const buildingFeats = extract(building);
    // convert floor level to number
    if (hasProp(unitFeats, "FloorLevel")) {
      unitFeats.FloorLevel = Number(unitFeats.FloorLevel) || 0;
    }
    // for Condo replace HeatingType with HeatingTypes
    if (hasProp(buildingFeats, "HeatingType")) {
      buildingFeats.HeatingTypes = buildingFeats.HeatingType;
      delete buildingFeats.HeatingType;
    }

    if (buildingFeats || unitFeats) {
      const condoFeats = {};
      if (buildingFeats) condoFeats.BuildingFeatures = buildingFeats;
      if (unitFeats) condoFeats.UnitFeatures = unitFeats;
      feats = { MultiResidentialBuilding: condoFeats };
    }
  } else {
    const resFeats = extract(getResidentialFeaturesKeys());
    if (resFeats) feats = { ResidentialBuilding: resFeats };
  }
  return feats ? { ResidentialLineOfBusiness: feats } : null;
};
