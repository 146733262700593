import React, { useState, useEffect, useCallback } from "react";
import { useDebouncedCallback } from "use-debounce";
import cx from "classnames";
import { isFunction, strval } from "../../lib/util";
import { updateInvalidList } from "../../lib/global-util";
import getLogger from "../../lib/debug-logger";

const log = getLogger("TextInput", 3);

const TextInput = (props) => {
  const {
    name,
    dataValue,
    refValue = dataValue,
    className,
    pattern,
    minValue,
    maxValue,
    maxLength = 32,
    numeric = false,
    changeCallback,
    disabled = false,
  } = props;

  const [value, setValue] = useState("");
  const [valid, setValid] = useState(true);
  const [dirty, setDirty] = useState(false);

  useEffect(() => {
    log(`input props`, { dataValue, refValue, pattern, minValue, maxValue, numeric }); //------------- log
  }, [dataValue, refValue, pattern, minValue, maxValue, numeric]);

  // validate input, discard changes on failure

  const validate = useCallback(
    (val) => {
      if (val === "" || val === "Unknown") return true; // allow empty input
      if (isFinite(minValue) && Number(val) < Number(minValue)) return false;
      if (isFinite(maxValue) && Number(val) > Number(maxValue)) return false;

      if (pattern) {
        const re = new RegExp(pattern);
        return re.test(val);
      }
      return true;
    },
    [pattern, minValue, maxValue]
  );

  const updateData = useCallback(() => {
    if (isFunction(changeCallback)) changeCallback(value);
  }, [value, changeCallback]);

  const debouncedUpdate = useDebouncedCallback(updateData, 999);

  useEffect(() => {
    setDirty(strval(value) !== strval(refValue)); // data might be 'number'
  }, [value, refValue, setDirty]);

  useEffect(() => {
    setValue(dataValue);
  }, [dataValue]);

  useEffect(() => {
    if (valid) {
      log(`setting input value '${value}'`); //---------- log
      debouncedUpdate(value);
    } else {
      log(`invalid value '${value}'`); //---------- log
    }
  }, [value, valid, debouncedUpdate]);

  useEffect(() => {
    const good = validate(value);
    setValid(good);
  }, [value, validate, setValid]);

  useEffect(() => {
    updateInvalidList(name, valid);
  }, [name, valid]);

  const handleInput = useCallback(
    (ev) => {
      let v = ev.target.value;
      if (numeric) v = v.replace(/\D+/g, "").replace(/^0+(\d+)$/, "$1");
      setValue(v);
    },
    [setValue, numeric]
  );

  return (
    <input
      name={name}
      type="text"
      className={cx(className, {
        invalid: !valid,
        dirty,
      })}
      value={value}
      maxLength={maxLength}
      disabled={disabled}
      onInput={handleInput}
    />
  );
};

export default TextInput;
