import React, { useEffect, useState } from "react";
import { useGlobal } from "reactn";
import cx from "classnames";
import useLanguage from "../useLanguage";
import TopNav from "./TopNav";
import NewSearchButton from "./NewSearchButton";

import "../../styles/avm/header.scss";
import { getBrandingId } from "../../lib/global-util";

/*
images: EN: 3246 x 301, FR: 3388 X 301
*/
const bannerEn = "/iClarify-Financial-Services-En.png"; // 3246 x 301
const bannerFr = "/iClarify-Financial-Services-Fr.png"; // 3388 X 301

const Header = (props) => {
  const [pageName] = useGlobal("PAGE_NAME");
  const [addressLine] = useGlobal("ADDRESS_LINE"); // search address as street address string
  const [addressLegalLine] = useGlobal("ADDRESS_LEGAL_LINE"); // search address as object
  const [username] = useGlobal("USERNAME");

  const [isBranded, setIsBranded] = useState(false); // custom brand applied
  const [isSearchView, setIsSearchView] = useState(false);
  const [isSPropertyView, setIsPropertyView] = useState(false);

  const { language, isFr } = useLanguage();

  useEffect(() => {
    if (!username) return;
    const brandId = getBrandingId();
    setIsBranded(!!brandId);
  }, [username]);

  useEffect(() => {
    setIsSearchView(!addressLine && !addressLegalLine);
  }, [addressLine, addressLegalLine, setIsSearchView]);

  useEffect(() => {
    setIsPropertyView((addressLine || addressLegalLine) && pageName === "main");
  }, [pageName, addressLine, addressLegalLine, setIsPropertyView]);

  return (
    <div
      className={cx(
        "page-header",
        { expanded: isSPropertyView, search: isSearchView },
        !isBranded ? "with-logo" : "no-logo",
        language
      )}
    >
      {!isBranded && (
        <div className={cx("iclarify-logo", language)}>
          <img src={isFr ? bannerFr : bannerEn} alt="iClarify" />
        </div>
      )}
      <div className="header-nav text-right">
        <TopNav />

        {isSPropertyView && (
          <div className="second-line text-right">
            <NewSearchButton />
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
