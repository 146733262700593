import React from "react";
import { useGlobal } from "reactn";
import useLanguage from "../useLanguage";
import { loadMarketValue } from "../../lib/oss-api";
import getLogger from "../../lib/debug-logger";

const log = getLogger("RecalcButton", 1);

const RecalcButton = () => {
  const { t } = useLanguage();

  const [recalcEnabled] = useGlobal("RECALC_ENABLED");

  const handleRecalcClick = () => {
    log("recalculation triggered"); //---------------- log
    loadMarketValue();
  };

  return (
    <button className="big-button" onClick={handleRecalcClick} disabled={!recalcEnabled}>
      {t("Recalculate")}
    </button>
  );
};

export default RecalcButton;
