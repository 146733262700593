let stage = process.env.REACT_APP_STAGE || "dev"; // "dev", "test" or "ft", "uat", "ct" or "prod"

let ossStage, // primary OSS API env't
  ossSchemaStage, // OSS schema backend doesn't require login and is handled separately
  authProxyStage, // API Gateway / proxy lambda endpoint for KeyCloak env't
  keycloakStage, // Keycloak env't
  debugLevel = 0; // 0 - no logs, otherwise only logs of same or lower non-zero levels

// initialize env't keys
ossStage = ossSchemaStage = keycloakStage = authProxyStage = stage;

// environments cross-mapping
if (["test", "ft", "dev"].includes(stage)) {
  ossStage = ossSchemaStage = keycloakStage = "test";
  debugLevel = 1; // always show top-level logs in console
}

// OSS endpoint selector
const getOssEndpoint = (ossEnv) => {
  const pref = ossEnv === "dev" ? "dev." : "www.";
  return `https://${pref}optaservice.com/OptaSingleService/`;
};

// Internal microservice endpoint
const getMsInternalEndpoint = (ossEnv) => {
  const env = ossEnv === "ct" ? "clienttest" : ossEnv;
  return `https://opta-single-service.${env}msenv-k8s.optaservice.com/`;
};

// Keycloak proxy lambda endpoint (API Gateway name: AVM)
const keycloakProxyEndpoint = ((envt) => {
  let prefix;
  switch (envt) {
    case "prod":
      prefix = "s6fe5j1d5a";
      break;
    case "ct":
      prefix = "5m5oyvljl9";
      break;
    case "uat":
      prefix = "r859th51da";
      break;
    case "ft":
    case "test":
      prefix = "3ivs5vexm9";
      break;
    default:
      //prefix = "c7t6i2sbji"; // dev
      prefix = "3ivs5vexm9"; // test
  }
  return `https://${prefix}.execute-api.ca-central-1.amazonaws.com/v1/`;
})(stage);

// Overlord API
const overlordEndpoint = ((envt) => {
  let prefix;
  switch (envt) {
    case "prod":
      prefix = "s6fe5j1d5a";
      break;
    case "ct":
      prefix = "5m5oyvljl9";
      break;
    case "uat":
      prefix = "r859th51da";
      break;
    case "ft":
    case "test":
      prefix = "3ivs5vexm9"; // test
      break;
    default:
      //prefix = "c7t6i2sbji"; // dev
      prefix = "3ivs5vexm9"; // test
  }
  return `https://${prefix}.execute-api.ca-central-1.amazonaws.com/v1/`;
})(stage);

const awsServiceUrls = {
  login: `${keycloakProxyEndpoint}login`,
  forgotPassword: `${keycloakProxyEndpoint}forgot-password`,
  resetPassword: `${keycloakProxyEndpoint}reset-password`,
  contactUs: `${keycloakProxyEndpoint}contact-us`,
  refresh: `${keycloakProxyEndpoint}refresh-token`,
};

export const googleMapKey =
  stage === "prod"
    ? "AIzaSyAt35X_xuLPnh0j9-6Khof4GBZy2j08aPI"
    : "AIzaSyCj2uD02atIh4n76uk1EygceFfuZXj9Ax0";

const config = {
  stage,
  ossStage,
  ossSchemaStage,
  authProxyStage,
  keycloakStage,
  keycloakProxyEndpoint,
  overlordEndpoint,
  debugLevel,
  googleMapKey,

  // maintain address history
  addressCacheOn: true,
  addressCacheSize: 10,
  addressCacheAll: true, // silently cache all adresses

  // Proxy to OSS Authentication and other APIs
  awsServiceUrls,

  // Google maps wrapper lambda endpoint
  googleMapEndpoint: "/map.html",

  // OSS endpoints
  ossApiEndpoint: getOssEndpoint(ossStage) + "json/" + ossStage,

  // endpoint OSS schema
  ossSchemaUrl: getOssEndpoint(ossSchemaStage) + ossSchemaStage + "?json=simplifiedschema.json",

  // OSS microservice endpoints implemented in AVM-2688
  ossMsApiEndpoint: getOssEndpoint(ossStage) + "ms/" + ossStage,

  // should only be used when ossMsApiEndpoint is not available
  ossMsInternalEndpoint: getMsInternalEndpoint(ossStage) + "json/ms",

  // OSS timeout (ms)
  ossApiTimeout: 60 * 1000,

  // static files base
  assetsBase: "/",
};

export default Object.freeze(config);
