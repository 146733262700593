import React from "react";
import useLanguage from "../useLanguage";

import "../../styles/avm/search-tips.scss";

const SearchTips = () => {
  const { t } = useLanguage();

  return (
    <div className="search-tips">
      <div className="search-tips-handle">
        <div className="search-tips-label">{t("Search Tips")}</div>
        <ul className="search-tips-content">
          <li>{t("SearchTip1", "Ex: 1001-185 Oneida Cres, Richmond Hill")}</li>
          <li>{t("SearchTip2", "Ex: 757 29Th St E, North Vancouver")}</li>
          <li>{t("SearchTip3", "Ex: 139 Langlais Rue, Les Mechins, QC, G0J1T0")}</li>
        </ul>
      </div>
    </div>
  );
};

export default SearchTips;
