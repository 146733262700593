import React, { useEffect, useState } from "react";
import { useGlobal } from "reactn";
import PropertyReview from "./PropertyReview";
import useLanguage from "../useLanguage";
import { separatePostal, strlen } from "../../lib/util";

import "../../styles/avm/property-header.scss";

// Property address component
const AddressComponent = () => {
  const [addressLine] = useGlobal("ADDRESS_LINE"); // property search address string

  // address parts from response data
  const [addressLineStreet] = useGlobal("ADDRESS_LINE_STREET");
  const [addressLineCity] = useGlobal("ADDRESS_LINE_CITY");

  const [addressLegalLine] = useGlobal("ADDRESS_LEGAL_LINE"); // if searching by legal description

  const [street, setStreet] = useState("");
  const [region, setRegion] = useState("");

  // produce street address parts
  useEffect(() => {
    if (!strlen(addressLine)) return;
    setStreet(addressLineStreet || addressLine);
    setRegion(addressLineCity || "");
  }, [addressLine, addressLineStreet, addressLineCity, setStreet, setRegion]);

  // format address by legal description
  useEffect(() => {
    if (strlen(addressLegalLine)) {
      setStreet(addressLegalLine);
      setRegion("");
    }
  }, [addressLegalLine, setRegion]);

  return street ? (
    <div className="property-address-box  bg-icon-pin">
      <span className="pab--street">{separatePostal(street)}</span>
      {!!region && [
        <span className="pab--separator">{", "}</span>,
        <span className="pab--city">{separatePostal(region)}</span>,
      ]}
    </div>
  ) : null;
};

const PropertyHeader = (props) => {
  const { t } = useLanguage();

  const [pageName] = useGlobal("PAGE_NAME");

  const [pid] = useGlobal("ADDRESS_PID");
  const [oak] = useGlobal("OAK");
  const [municipalCode] = useGlobal("MUNICIPAL_CODE");
  const [legalDescription] = useGlobal("ADDRESS_LEGAL_DESCRIPTION");

  //-------------------------------------------------

  return (
    <div className="property-header">
      <div className="property-header-top">
        <AddressComponent />
        {props.children}
      </div>
      {pageName === "main" && (
        <div className="infobox">
          <div className="info-table table">
            <div className="info-item tr">
              <div className="info-label td">{t("Property Identifier")}</div>
              <div className="td info-text">{pid || "-"}</div>
            </div>
            <div className="info-item tr">
              <div className="info-label td">{t("Municipal Code")}</div>
              <div className="td info-text municipal-code">{municipalCode || "-"}</div>
            </div>
            <div className="info-item tr">
              <div className="info-label td">{t("Legal Description")}</div>
              <div className="td info-text legal-description">{legalDescription || "-"}</div>
            </div>
            <div className="info-item tr">
              <div className="info-label td">{t("OptaAddressKey")}</div>
              <div className="td info-text opta-address-key">{oak || "-"}</div>
            </div>
          </div>
          <PropertyReview />
        </div>
      )}
    </div>
  );
};

export default PropertyHeader;
