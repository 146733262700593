// getting construction features options from OSS type definitions
// https://www.optaservice.com/optasingleservice/test?json=simplifiedschema.json

import storage from "./localstorage";
import config from "../config";
import { isObject, isString, hasProp, getProp } from "./util";
import { getHttpClient } from "./api";

import getLogger from "./debug-logger";
import { getGlobalValue, setGlobalValue } from "./global-util";

const log = getLogger("oss-schema", 2);

const { ossSchemaUrl, ossApiTimeout } = config;

// enumerations not defined in OSS schema dataset
const missingTypes = {
  "xsd:boolean": ["true", "false"],
};

// extract values for OSS schema enumeration types
const getOptions = (ossTypesObj, dataKey) => {
  const x = getProp(ossTypesObj, dataKey);
  if (Array.isArray(x)) return x; // final array of values
  if (isString(x)) return getOptions(ossTypesObj, x); // dataKey is reference to another key
  if (isObject(x)) {
    const o = Object.create(null);
    for (let [k, v] of Object.entries(x)) {
      let y = getOptions(ossTypesObj, v);
      if (Array.isArray(y)) o[k] = [...y];
    }
    return o;
  }
  return null;
};

const getOssSchema = async () => {
  const opt = {
    method: "GET",
    url: ossSchemaUrl,
    timeout: ossApiTimeout,
    validateStatus: null,
  };
  const client = getHttpClient();
  log(`sending request for OSS schema`, opt);
  try {
    const res = await client.request(opt);
    if (res) {
      if (hasProp(res, "data")) {
        const schema = res.data;
        if (isObject(schema)) {
          log(`OSS schema received`, schema); //---------------- log
          // add missing enumerations and return
          return Object.assign({}, missingTypes, schema);
        }
      }
      log(`no data returned`); //-------------------------- LOG
    }
    if (!res || !res.data) {
      log(`OSS API call failed`); //-------------------------- LOG
    }
  } catch (x) {
    const msg = (typeof x === "object" && x.message) || x + "";
    log(msg); //---------------- log
  }
};

// load data schema globally
export const loadDataSchema = async () => {
  if (getGlobalValue("DATA_SCHEMA")) {
    return;
  }
  let schema = null;
  const storageKey = "__dataSchema";

  schema = storage.get(storageKey);
  if (schema) {
    log("stored data schema", schema); //--------------- log
    setGlobalValue("DATA_SCHEMA", schema);
    return;
  }

  log(`getting data schema from API`); //--------------- log
  schema = await getOssSchema();
  if (!isObject(schema)) {
    log(`could not download OSS schema`); //--------------- log
    return;
  }
  // filter and transfom original OSS schema object.
  // handling property stats - both top level entries and a group

  const propertyStats = {};
  ["PropertyOccupancy", "ZoningDescription", "Tenure", "RentalActivity"].forEach((k) => {
    let kk = k + "Type";
    if (kk in schema) {
      schema[k] = schema[kk];
      delete schema[kk];
      propertyStats[k] = schema[k];
    }
  });

  const transformed = {
    residential: getOptions(schema, "ResponseFinancialResidentialConstructionFeaturesType"),
    condoBuilding: getOptions(schema, "ResponseCondoBuildingFeaturesType"),
    condoUnit: getOptions(schema, "ResponseCondoUnitFeaturesType"),
    rentalTypeValues: getOptions(schema, "RentalUnitType"),

    propertyStats, // grouped together
  };

  // blend in property stats for residential
  Object.assign(transformed.residential, propertyStats);

  log(`storing transformed OSS schema`, transformed); //------------- log
  storage.set(storageKey, transformed);
  setGlobalValue("DATA_SCHEMA", transformed);
};

// provide construction features' options from local storage or API
export const getFeatsOptions = (dataSchema = null, propType = "") => {
  if (!dataSchema) dataSchema = getGlobalValue("DATA_SCHEMA");
  if (!propType) propType = getGlobalValue("PROPERTY_TYPE") || "";
  if (!dataSchema) {
    return null;
  }
  const featsMap =
    propType === "Condominium"
      ? { ...dataSchema.condoBuilding, ...dataSchema.condoUnit }
      : propType === "Residential"
      ? { ...dataSchema.residential }
      : { ...dataSchema.residential, ...dataSchema.condoBuilding, ...dataSchema.condoUnit };
  return featsMap;
};
