import storage from "./localstorage";
import config from "../config";
import apiCall from "./api";
import { getGlobalValue, setGlobal, setGlobalValue } from "./global-util";
import { getProp, digObject, isNone, isObject, isString, bool, lowerCase } from "./util";
import { extractProducts } from "./products";
import getLogger from "./debug-logger";
import { removePropertyInfo } from "./local-data";

const log = getLogger("session", 1);

//-----------------------------------------------------
// store globals to be restored on page load
//-----------------------------------------------------
const storeInitState = (objOrString) => {
  let initState = null;
  if (isString(objOrString)) {
    initState = { MESSAGE: objOrString };
  } else if (isObject(objOrString)) {
    initState = { ...objOrString };
  }
  if (isNone(initState)) {
    return;
  }
  // provide path where stored state was set
  initState.path = window.location.pathname;
  // store the future initial state
  log("memorizing initial state", initState); // ------------ log
  storage.set("initState", initState);
};

//-----------------------------------------------------
// restore globals previously stored
//-----------------------------------------------------
const restoreInitState = () => {
  const stateObj = storage.pop("initState");
  if (!isObject(stateObj)) {
    return;
  }
  // validate original path
  if (getProp(stateObj, "path") !== window.location.pathname) {
    return;
  }
  delete stateObj.path; // no longer needed
  log("applying stored state"); // -------------- log
  setGlobal(stateObj);
};

//-----------------------------------------------------
// reload page, optionally passing around error message
//-----------------------------------------------------
export const reloadPage = (objOrMessage = null) => {
  storeInitState(objOrMessage);
  log("triggering page reload"); // ------------ log
  window.location.reload();
};

//-----------------------------------------------------
// navigate to home page, optionally passing around error message
//-----------------------------------------------------
export const goHome = (objOrMessage = null) => {
  storeInitState(objOrMessage);
  if (window.location.pathname === "/") {
    return reloadPage(objOrMessage);
  }
  log("triggering navigation to home page"); // ------------ log
  window.location.replace("/");
};

//-----------------------------------------------------
// navigate to specified page
//-----------------------------------------------------
export const goToPage = (name, cleanup = false) => {
  if (cleanup) removePropertyInfo(); // delete last property data from localstorage
  window.location.href = name && name !== "/" ? `${name}.html` : "/";
};

//-----------------------------------------------------
// invalidate session, navigate to 'Sign In' screen
//-----------------------------------------------------
export const dropSession = (delay = 999) => {
  setGlobalValue("SIGNING_OUT", true);
  delay = Math.max(Number(delay, 999));
  log(`invalidating session in ${delay} ms`); // ------------ log
  removePropertyInfo();
  storage.clear();
  // navigate to home page
  setTimeout(() => {
    setGlobalValue("SIGNING_OUT", false);
    goHome();
  }, delay);
};

//-----------------------------------------------------
// read session info stored in localstorage
//-----------------------------------------------------
const getStoredSession = () => storage.get("SESSION");

//-----------------------------------------------------
// Set up session object, store it in locastorage and populate global variables
//-----------------------------------------------------
const setSession = (userInfo) => {
  if (!getProp(userInfo, "access_token")) {
    return dropSession(); // --------------- delay before reload
  }
  const prevSession = getStoredSession();
  //log("Stored session:", prevSession); // ------------ log
  let user = getProp(userInfo, "username"); // set upon successful login
  if (!user && isObject(prevSession)) {
    user = getProp(prevSession, "USERNAME"); // restoring user upon session refresh
  }
  // build new or updated session object;
  // merge stored and returned user data, because some entries might be missing on refresh
  const origInfo = getProp(prevSession, "USER_INFO") || {};
  const updInfo = { ...origInfo, ...userInfo };
  const productsConf = extractProducts(updInfo);

  const sessObj = {
    ...prevSession,
    USERNAME: user,
    USER_INFO: { ...updInfo },
    //
    ...productsConf, // setting PRODUCTS_MAP and availability flags
  };
  log(`user attributes`, getProp(updInfo, "user_attributes")); //------------------------ log
  log(`configured products`, productsConf); //------------------------ log

  // set anticipated expiration time - as provided or 10hrs
  const ttl = Number(getProp(userInfo, "expires_in", 0)) * 1000;
  sessObj.SESSION_EXPIRES = ttl
    ? ttl + Date.now()
    : getProp(prevSession, "SESSION_EXPIRES") || Date.now() + 36 * 10 ** 6;
  //
  // set or update user role (for User Management) and eligibility for property review
  const userAtts = getProp(userInfo, "user_attributes");
  // user role
  let role = getProp(userAtts, "UserRole");
  if (isNone(role)) role = getProp(prevSession, "USER_ROLE");
  sessObj.USER_ROLE = Number(role) || 0;

  // editing constuction features is allowed?
  let prefill = getProp(userAtts, "AVMReadOnlyPrefill");
  if (isNone(prefill)) prefill = getProp(prevSession, "READONLY_PREFILL");
  sessObj.READONLY_PREFILL = bool(prefill);

  // request for property review is allowed?
  let review = getProp(userAtts, "AVMReview");
  if (isNone(review)) review = getProp(prevSession, "REVIEW_ALLOWED");
  sessObj.REVIEW_ALLOWED = bool(review);

  // Overlord role is allowed?
  let overlord = getProp(userAtts, "AVMOverlord");
  if (isNone(overlord)) overlord = getProp(prevSession, "OVERLORD");
  sessObj.OVERLORD = bool(overlord);
  //
  // Reveal Estimated Value Range on initial data load?
  let revealRange = getProp(userAtts, "AVMEVRReveal");
  if (isNone(revealRange)) revealRange = getProp(prevSession, "REVEAL_EVR");
  sessObj.REVEAL_EVR = bool(revealRange);

  // Reveal Market Value on initial data load?
  let revealAvm = getProp(userAtts, "AVMReveal");
  if (isNone(revealAvm)) revealAvm = getProp(prevSession, "REVEAL_AVM");
  sessObj.REVEAL_AVM = bool(revealAvm);

  // brand, if assigned
  let brand = getProp(userAtts, "AVMBrand");
  if (isNone(brand)) brand = getProp(prevSession, "BRAND");
  sessObj.BRAND = lowerCase(brand || "");

  //log("updating local storage and global state", sessObj); // ------------ log

  storage.set("SESSION", sessObj);
  setGlobal(sessObj);
  restoreInitState();
};

//-----------------------------------------------------
// User login API call
//-----------------------------------------------------
export const login = async (username, password) => {
  const opts = {
    method: "post",
    url: config.awsServiceUrls.login,
    data: {
      user: username,
      pass: password,
      stage: config.keycloakStage,
    },
  };
  log("sending login request:", opts); //---------------- log
  const userInfo = await apiCall(opts);
  log("response data:", userInfo); //---------------- log
  if (getProp(userInfo, "access_token")) {
    // will fail if no access_token returned
    userInfo.username = username;
    setSession(userInfo);
    return;
  }
  const retryDelay = 9999; // delay after failed login

  const err = getProp(userInfo, "error") || "login error";
  setGlobalValue("ERROR", err);
  log(`retry delay ${retryDelay}ms`); //--------------- log
  dropSession(retryDelay);
};

//-----------------------------------------------------
// Refresh token API call
//-----------------------------------------------------
export const restoreSession = async () => {
  setGlobalValue("REFRESHING", true);
  const refreshToken = digObject(getStoredSession(), "USER_INFO", "refresh_token");
  if (refreshToken) {
    const opts = {
      method: "post",
      url: config.awsServiceUrls.refresh,
      data: {
        refresh_token: refreshToken,
        stage: config.keycloakStage,
      },
    };
    log("sending refresh token request:", opts); //---------------- log
    const userInfo = await apiCall(opts);
    log("response data:", userInfo); //---------------- log
    setSession(userInfo);
  }
  setGlobalValue("REFRESHING", false);
};

//-----------------------------------------------------
// inactivity timeout monitoring
//-----------------------------------------------------
((maxTimeMs) => {
  let initTime = Date.now(); // last action timestamp

  const isLoggedIn = () => !!getGlobalValue("USER_INFO");

  const checkTime = () => {
    const checkInterval = 60 * 1000;
    if (isLoggedIn()) {
      const delta = Date.now() - initTime;
      if (delta > maxTimeMs) {
        return dropSession();
      }
      log(`${Math.floor(delta / 1000)}s`); //------- log
    } else {
      initTime = Date.now();
    }
    setTimeout(checkTime, checkInterval); // check timestamp every checkInterval
  };

  let timer = null; // for throttling events

  const handleAction = () => {
    if (timer || !isLoggedIn()) return; // throttling fast-firing multiple events

    const delta = Date.now() - initTime;
    if (delta > maxTimeMs) {
      return dropSession();
    }
    initTime = Date.now();
    timer = setInterval(() => {
      clearInterval(timer);
      timer = null;
    }, 5000);
  };

  window.addEventListener("mousemove", handleAction);
  window.addEventListener("scroll", handleAction);
  window.addEventListener("keydown", handleAction);

  // start monitoring inactivity
  checkTime();
})(1000 * 60 * 60 * 3); // set inactivity timeout here
