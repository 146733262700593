import React from "react";
import { useGlobal } from "reactn";
import cx from "classnames";
import useLanguage from "../useLanguage";
import { getProp } from "../../lib/util";
import PropertyFeature from "./PropertyFeature";
import RentalTypeSelect from "./RentalTypeSelect";
import { getResidentialFeaturesByColumns } from "../../lib/features-config";

// Features listed by columns and rendering order
const [featLeft, featRight] = getResidentialFeaturesByColumns();

const ResidentialFeatures = (props) => {
  const { featuresSchema, currentFeatures: features, formLocked = false } = props;

  const { t } = useLanguage();

  const [hasRental] = useGlobal("PROD_AVAIL_RENTAL_VAL");

  //--------------------------------------------------
  if (!features) return null;
  //--------------------------------------------------

  return (
    <div className="property-features">
      <h2>{t("Property Features")}</h2>
      <div className="pf--content">
        {[featLeft, featRight].map((keyList, num) => {
          return (
            <div key={num} className={cx("pf--list", `col${num + 1}`, "table")}>
              {keyList.map((formKey, idx) => {
                return (
                  <PropertyFeature
                    key={idx}
                    formKey={formKey}
                    refValue={features[formKey]}
                    values={getProp(featuresSchema, formKey)}
                    emptyAllowed={true}
                    locked={!!formLocked}
                  />
                );
              })}
            </div>
          );
        })}
      </div>
      {!!hasRental && (
        <div className="pf--content">
          <RentalTypeSelect />
        </div>
      )}
    </div>
  );
};

export default ResidentialFeatures;
