// Rental Unit Type selector (dropdown)
import React, { useCallback, useEffect, useState } from "react";
import { useGlobal, setGlobal } from "reactn";
import cx from "classnames";
import useLanguage from "../useLanguage";
import Dropdown from "./Dropdown";
import { getProp, isArray, listSize, stringIncludes } from "../../lib/util";
import { isCondo } from "../../lib/features-config";

import getLogger from "../../lib/debug-logger";
import { updateStoredValue } from "../../lib/local-data";

const log = getLogger("RentalTypeSelect", 3);

// reset globals related to rental valuation if rental type is empty
const dropRentalVars = () => {
  setGlobal({
    RENTAL_VALUE_ERROR: "",
    RENTAL_RANGE: null,
    RENTAL_VALUE: 0,
  });
};

const labelText = "Rental Unit Type";

const RentalTypeSelect = (props) => {
  const [lang] = useGlobal("LANGUAGE");
  const [dataSchema] = useGlobal("DATA_SCHEMA");
  const [showRental] = useGlobal("SHOW_RENTAL"); // this is set by Province, not by avaiable product
  const [hasRental] = useGlobal("PROD_AVAIL_RENTAL_VAL");
  const [readonly] = useGlobal("READONLY_PREFILL");
  const [propertyType] = useGlobal("PROPERTY_TYPE");
  const [rentalType, setRentalType] = useGlobal("RENTAL_TYPE");

  // default Rental Type selection may be set by Architectural Style feature
  const [archStyleRes] = useGlobal("ArchitecturalStyleType");
  const [archStyleCondo] = useGlobal("MultiResidentialStyleType");

  const [archStyle, setArchStyle] = useState(""); // actual architectural style
  //const [selectable, setSelectable] = useState(false); // allow selection other than default

  const [typeValues, setTypeValues] = useState(null);
  const [typeLabels, setTypeLabels] = useState(null);

  const { t, language } = useLanguage();

  // --------------- defining actual arch style
  useEffect(() => {
    const stl = isCondo(propertyType) ? archStyleCondo : archStyleRes;
    log(`setting Arch Style to '${stl}'`); //------------------------------ log
    setArchStyle(stl);
  }, [propertyType, archStyleCondo, archStyleRes, setArchStyle]);

  // ----------------- providing values for dropdown menu
  useEffect(() => {
    if (!dataSchema) return;

    let vals = getProp(dataSchema, "rentalTypeValues"); // list of values from OSS schema

    if (!isArray(vals)) {
      log(`rentalTypeValues must be an array`);
      return;
    }

    log(`provided Rental Type values`, vals); //----------------- log

    // actual value for Complete Property
    const actualProperty = isCondo(propertyType) ? "Condo" : "Residential";

    // populating value/label pairs for dropdown;
    // put complete property value in the 1st place, remove Condo and Residential
    vals = vals.filter((it) => !["Complete Property", "Condo", "Residential"].includes(it));
    vals = ["", actualProperty, ...vals];

    const labels = vals.map((it) => t(it === actualProperty ? "Complete Property" : it));

    setTypeValues(vals);
    setTypeLabels(labels);
    log(`setting Rental Type values`, vals); //----------------- log
    log(`setting Rental Type labels (language: ${language})`, labels); //----------------- log
  }, [propertyType, dataSchema, language, setTypeValues, setTypeLabels, t]);

  // ------------ setting default rental type based on architectural style
  useEffect(() => {
    if (!archStyle) {
      log(`architectural style not set`); //------------ log
      return;
    }
    if (!listSize(typeValues)) {
      log(`rental type options not ready`, typeValues); //------------ log
      return; // selector options not ready yet
    }
    log(`Architectural Style: '${archStyle}'`); //----------------- log

    dropRentalVars(); // reset previously populated data

    // if architectural style matches any option, set that option as default rental type
    let rental = "";
    typeValues.forEach((it) => {
      if (stringIncludes(it, archStyle, true)) {
        rental = it;
        return;
      }
    });
    setRentalType(rental);
    log(`setting default Rental Type '${rental}'`); //-------------- log
  }, [typeValues, propertyType, archStyle, setRentalType]);

  // ------------------- Rental Type selection handler
  const handleChange = useCallback(
    (val) => {
      dropRentalVars();
      setRentalType(val);
      updateStoredValue("RENTAL_TYPE", val);
      log(`setting Rental Type '${val}'`); //--------------------- log
    },
    [setRentalType]
  );

  //-------------------------------------------
  if (readonly || !hasRental || !showRental || !typeLabels) return null;
  //-------------------------------------------

  return (
    <div clasName={cx("rental-select table", props.className)}>
      <div className="table-row">
        <span className={cx("feature-label", "table-cell", lang)} title={t(labelText)}>
          {t(labelText)}
        </span>
        <span className="table-cell">
          <Dropdown
            className="feature-value"
            vals={typeValues}
            labels={typeLabels}
            initValue={rentalType}
            placeholder={t("Not entered")}
            changeCallback={handleChange}
          />
        </span>
      </div>
    </div>
  );
};

export default RentalTypeSelect;
