import React from "react";
import useLanguage from "../useLanguage";
import "../../styles/avm/property-error.scss";

const defaultClickAction = () => {
  window.location.pathname === "/" ? window.location.reload() : window.location.replace("/");
};

const PropertyError = (props) => {
  const {
    message = "",
    title = "",
    showReload = true,

  } = props;

  const { t } = useLanguage();

  if (!message) return null;

  return (
    <div className="property-data-error">
      <div>
        {!!title && <h3>{t(title)}</h3>}
        {!!message && <div className="error-text">{t(message)}</div>}
        {showReload && (
          <div>
            <button
              className="reset-btn"
              onClick={defaultClickAction}
            >
              {t("Refresh Page")}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default PropertyError;
