import React, { useEffect } from "react";
import { useGlobal } from "reactn";
import AvmLayout from "./AvmLayout";
import PropertyView from "./PropertyView";
import SignIn from "./SignIn";
import SearchView from "./SearchView";
import { removePropertyInfo, popInitAddress, storedPropertyDataExists } from "../../lib/local-data";
import getLogger from "../../lib/debug-logger";

const log = getLogger("MainView", 3);

const initAddr = popInitAddress(); // extract address saved in localstorage for Reset action

// if initAddr is set, remove locally stored property data to force fresh reload
if (initAddr) {
  log(`Reset requested, dropping stored property data`); //-------------- log
  removePropertyInfo();
}

const MainView = () => {
  const [userInfo] = useGlobal("USER_INFO");
  const [addrStr, setAddrStr] = useGlobal("ADDRESS_LINE");
  const [legalAddrObj] = useGlobal("ADDRESS_REQ_OBJECT"); // exists when searching by legal description

  // use stored address when data reset is triggered
  useEffect(() => {
    log(`initAddr: ${initAddr}, addrStr: ${addrStr}`); //--------------- log
    if (initAddr && !addrStr) setAddrStr(initAddr);
  }, [addrStr, setAddrStr]);

  if (!userInfo) {
    return <SignIn />;
  }

  if (!addrStr && !legalAddrObj && !storedPropertyDataExists()) {
    return <SearchView />;
  }

  return (
    <AvmLayout>
      <PropertyView />
    </AvmLayout>
  );
};

export default MainView;
