import { useEffect, useCallback, useState } from "react";
import { useGlobal } from "reactn";
import { checkLanguage } from "../lib/localstorage";
//import storage from "local-storage";
import labelsEn from "../languages/en";
import labelsFr from "../languages/fr";
import labelsCondoEn from "../languages/condo-en";
import labelsCondoFr from "../languages/condo-fr";
import { getProp, hasProp, lowerCase } from "../lib/util";
import { EN, FR } from "../lib/globals"; // language signatures
import { isCondo } from "../lib/features-config";
import { setGlobalValue } from "../lib/global-util";
import getLogger from "../lib/debug-logger";

const log = getLogger("useLanguage", 2);

// language signatures
export { EN, FR };

export const initLanguage = (newLang = null) => {
  let lang = checkLanguage(newLang);
  log(`initializing language '${lang}'`); //------------- log
  setGlobalValue("LANGUAGE", lang);
  return lang;
};

export function useLanguage() {
  const [language] = useGlobal("LANGUAGE");
  const [propertyType] = useGlobal("PROPERTY_TYPE"); // some data keys will be translated differently

  const [labels, setLabels] = useState(null);
  const [lcLabels, setLcLabels] = useState(null); // same, but with keys in lower case

  useEffect(() => {
    if (!language) initLanguage();
  }, [language]);

  useEffect(() => {
    const dict = language === FR ? labelsFr : labelsEn;
    if (isCondo(propertyType)) {
      const condoLabels = language === FR ? labelsCondoFr : labelsCondoEn;
      Object.assign(dict, condoLabels);
    }
    const lcDict = (() => {
      let o = {};
      for (let k in dict) o[lowerCase(k)] = dict[k];
      return o;
    })();
    setLabels(dict);
    setLcLabels(lcDict);
  }, [language, propertyType, setLabels, setLcLabels]);

  const getLabel = useCallback(
    (key, ...params) => {
      if (!key || !labels) return ""; // translate falsy keys into empty string
      const paramNum = params.length;
      let label = getProp(labels, key);
      if (!label) label = getProp(lcLabels, lowerCase(key), key); // use key as label if value is not set
      // substitute placeholders with params, if provided;
      // duplicate placeholders won't be replaced, use separate ones
      if (paramNum > 0 && label.includes("%")) {
        for (let i = 0; i < paramNum; i++) {
          label = label.replace(`%${i + 1}`, params[i]); // populate placeholders like '%1', '%2'
        }
      }
      return label;
    },
    [labels, lcLabels]
  );

  const isTranslated = useCallback(
    (label) => {
      const labels = language === FR ? labelsFr : labelsEn;
      return hasProp(labels, label);
    },
    [language]
  );

  return {
    t: getLabel,
    language,
    isTranslated,
    isEn: language === EN,
    isFr: language === FR,
    EN,
    FR,
  };
}

export default useLanguage;
