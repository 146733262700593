import React from "react";
import { useGlobal } from "reactn";
import { dropSession, goHome, restoreSession } from "../lib/session";
import { isAdminRole } from "../lib/user-roles";

const withSession = (WrappedComp) => {
  return (props) => {
    const [username] = useGlobal("USERNAME");
    const [userInfo] = useGlobal("USER_INFO");
    const [userRole] = useGlobal("USER_ROLE");
    const [reviewAllowed] = useGlobal("REVIEW_ALLOWED");
    const [overlord] = useGlobal("OVERLORD");
    const [exptime] = useGlobal("SESSION_EXPIRES");
    const [refreshing] = useGlobal("REFRESHING");

    return (
      <WrappedComp
        username={username}
        userInfo={userInfo}
        userRole={userRole}
        isAdmin={isAdminRole(userRole)}
        reviewAllowed={!!reviewAllowed}
        overlord={!!overlord}
        sessionExpires={exptime}
        loggedIn={!!userInfo}
        dropSession={dropSession}
        goHome={goHome}
        restoreSession={restoreSession}
        refreshing={!!refreshing}
        {...props}
      />
    );
  };
};

export default withSession;
