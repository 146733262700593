import React from "react";
//import { useGlobal } from "reactn";
import cx from "classnames";
import useLanguage from "../useLanguage";
import { dropReport, saveUpdatedStats } from "../../lib/local-data";
import { updateReportUrl } from "../../lib/oss-api";
import { dropComparables } from "../../lib/global-util";
import getLogger from "../../lib/debug-logger";


const log = getLogger("SaveButton", 1);

const SaveButton = (props) => {
  const { disabled } = props;

  const { t, language } = useLanguage();

  // original or updated stats object JSON
  //const [updatedStatsJson, setUpdatedStatsJson] = useGlobal("STATS_UPDATED");
  // current (updated/unsaved) stats object JSON
  //const [statsJson] = useGlobal("STATS_CURRENT");

  // Save logic: if construction features changed w/o value recalculation
  // - update current features snapshot, update value/range, and reset list of changed features;
  // - update Report URL
  // These tasks will be triggered by ReportButton if REPORT_URL is set to null
  const handleSave = () => {
    saveUpdatedStats();

    log("report dropped, save triggered"); //-------------- log
    dropComparables(); //----- if comparables already present, they're no longer valid as construction features change
    dropReport();
    updateReportUrl();
  };

  return (
    <button
      className={cx("big-button", "save-btn", language)}
      onClick={handleSave}
      disabled={disabled}
    >
      {t("Save")}
    </button>
  );
};

export default SaveButton;
