import React, { useCallback, useEffect, useState } from "react";

import lots, { formatLegalAddress } from "../../lib/legal-land-lot-data";
import useLanguage from "../useLanguage";
import Dropdown from "./Dropdown";
import getLogger from "../../lib/debug-logger";
import { setAndStoreGlobals } from "../../lib/local-data";

const log = getLogger("SearchByLegal", 1);

const addBlank = (arr) => ["", ...arr];

const provinceList = Object.keys(lots);

const provinceOptions = addBlank(provinceList);

const SearchByLegal = (props) => {
  const { t } = useLanguage();

  const [province, setProvince] = useState("");
  const [quarter, setQuarter] = useState("");
  const [section, setSection] = useState("");
  const [township, setTownship] = useState("");
  const [range, setRange] = useState("");
  const [meridian, setMeridian] = useState("");

  // initial selections
  useEffect(() => {
    if (!province) setProvince(provinceOptions[0]);
  }, [province, setProvince]);

  const updateProvince = (prov) => {
    setProvince("");
    if (!prov) return;
    setTimeout(() => {
      setProvince(prov);
    }, 10);
  };

  const handleSearch = useCallback(() => {
    const addrObj = {
      LegalDescription: {
        Quarter: quarter,
        Range: range,
        Section: section,
        Township: township,
        Meridian: meridian,
      },
      Province: province,
    };
    log("Searching by legal description", addrObj); //------------------ log

    // property data load will be triggered by setting those global vars
    setAndStoreGlobals({
      ADDRESS_REQ_OBJECT: addrObj,
      SEARCH_BY_LEGAL: true,
      ADDRESS_LEGAL_LINE: formatLegalAddress(addrObj),
    });
  }, [province, quarter, section, township, range, meridian]);

  return (
    <div className="advanced-search">
      <div className="advanced-search-box">
        <div className="input-cell">
          <div className="input-label">{t("Province")}</div>
          <Dropdown
            vals={[...provinceOptions]}
            initValue={province}
            changeCallback={(v) => {
              updateProvince(v);
            }}
          />
        </div>

        {!!province && [
          <div className="input-cell scrollable">
            <div className="input-label">{t("Section")}</div>
            <Dropdown
              labels={["", ...lots[province].quarter.options]}
              vals={["", ...lots[province].quarter.values]}
              initValue={quarter}
              changeCallback={(v) => {
                setQuarter(v);
              }}
            />
          </div>,

          <div className="input-cell scrollable">
            <div className="input-label">{t("Quarter/LSD")}</div>
            <Dropdown
              labels={["", ...lots[province].section.options]}
              vals={["", ...lots[province].section.values]}
              initValue={section}
              changeCallback={(v) => {
                setSection(v);
              }}
            />
          </div>,

          <div className="input-cell scrollable">
            <div className="input-label">{t("Township")}</div>
            <Dropdown
              labels={["", ...lots[province].township.options]}
              vals={["", ...lots[province].township.values]}
              initValue={township}
              changeCallback={(v) => {
                setTownship(v);
              }}
            />
          </div>,

          <div className="input-cell scrollable">
            <div className="input-label">{t("Range")}</div>
            <Dropdown
              labels={["", ...lots[province].range.options]}
              vals={["", ...lots[province].range.values]}
              initValue={range}
              changeCallback={(v) => {
                setRange(v);
              }}
            />
          </div>,

          <div className="input-cell">
            <div className="input-label">{t("Meridian")}</div>
            <Dropdown
              labels={["", ...lots[province].meridian.options]}
              vals={["", ...lots[province].meridian.values]}
              initValue={meridian}
              changeCallback={(v) => {
                setMeridian(v);
              }}
            />
          </div>,
        ]}
        <div className="input-cell">
          <button
            className="search-icon search-btn"
            disabled={!quarter || !section || !township || !range || !meridian}
            onClick={() => handleSearch()}
          ></button>
        </div>
      </div>
    </div>
  );
};

export default SearchByLegal;
