const labels = {
  true: "Yes",
  false: "No",
  "Address not found": "ADDRESS NOT FOUND",
  Aluminum: "Aluminium",
  ArchitecturalStyleType: "Architectural Style",
  //AuxiliaryHeatingType: "Auxiliary Heating Type",
  BathroomCount: "Bathrooms",

  Clear: "Clear",
  CommercialIndicator: "Commercial Indicator",
  ComparablesNotAvailable: "No Comparables are available at this time.",
  Confidence: "Confidence",

  DistanceFromSubjectProperty: "Distance to Subject Property (kms)",

  EnterAddress: "Enter an address or postal code",
  EnterYourPassword: "Enter your password",
  EstimatedValue: "Estimated Value",
  EstimatedValueRange: "Estimated Value Range",
  ExteriorWallType: "Exterior Wall Type",

  FinishedBasement: "Finished Basement",
  FloorLevel: "Floor Level",
  FoundationType: "Foundation Type",

  GarageNumberOfCars: "Garage Size",
  GarageType: "Garage Type",

  HeatingType: "Heating Type",
  KitchenCount: "Kitchens",
  LotDepth: "Lot Depth (ft)",
  LotFrontage: "Lot Frontage (ft)",
  LotSize: "Lot Size (sq ft)",
  MultiResidentialStyleType: "Architectural Style",
  NBathrooms: "%1 Bathrooms",
  NBedrooms: "%1 Bedrooms",
  NoGarage: "No Garage",
  NCarsGarage: "%1 Car Garage",

  NoSearchResultsMessage:
    "Keep typing to complete address or postal code - We can still help find your property.",

  NumberOfBathrooms: "Bathrooms",
  NumberOfBedrooms: "Bedrooms",
  NumberOfDens: "Dens",
  NumberOfStoreys: "Building Storeys",
  OneBathroom: "1 Bathroom",
  OneBedroom: "1 Bedroom",
  OneCarGarage: "1 Car Garage",
  OptaAddressKey: "Opta Address Key",
  OutbuildingPresent: "Outbuilding",

  ParkingType: "Parking Type",
  PlumbingType: "Plumbing Type",
  PrimaryHeatingType: "Heating Type",

  PropertyOccupancy: "Occupancy Type",

  RecalculateRange: "Recalculate Range",
  RecalculateValue: "Recalculate Value",
  RefreshMessage: "Click Refresh Page to search again.",
  RoofSurface: "Roof Surface",
  RoofType: "Roof Type",

  SearchTip1: "Search unit numbers as a prefix. %1",
  SearchTip2: "Search street direction after street name. %1",
  SearchTip3: "Search with the street name before road type in Quebec. %1",

  SepticSystem: "Septic System",
  SewageType: "Sewage Type",
  SquareFootage: "Total Floor Area (Sq. Ft.)",
  StoreyCount: "Building Storeys",
  Support: "Support",
  SwimmingPoolType: "Swimming Pool",
  Title: "Property Features",
  TotalFloorArea: "Total Floor Area (Sq. Ft.)",
  UnknownGarage: "Unknown Garage",
  WaterType: "Water Type",
  YearBuilt: "Year Built",
  ZoningDescription: "Zoning Description",

  Login: "Sign In",

  ForgotPasswordSuccess: "An email has been sent to your inbox",
  ForgotPasswordError: "An error has occurred, if this persists contact technical support",
  ResetPasswordSuccess: "Your password has been reset successfully. Return to login page",
  ContactUsSuccess: "Your message has been sent. We will be contacting you shortly",

  // Custom error messages
  DATA_ERROR: "Data error",
  PREFILL_ERROR: "Property construction features are not available at this time",
  IMAGERY_ERROR: "Imagery data is not available at this time",
  AVM_ERROR: "Market valuation not available",
  RENTAL_VALUE_ERROR: "Rental valuation not available",

  AVM_FAILED: "No valuation information available for this property",

  LISTINGS_ERROR: "No listing information available for this property.",
  SALES_ERROR: "No sale information available for this property.",
  ASSESMENTS_ERROR: "No assessment information available for this property",
  RENTALS_LIST_ERROR: "No rental information available for this property",
  STATS_ERROR: "No property stats information available for this property",
  METRICS_ERROR: "No market metrics available at this time",

  // for Overlord
  PROPERTY_TYPE_ERROR: "Property Type error",

  HOUSE_PRICE_INFO:
    "House Price Index based on current average sales price in the neighbourhood indexed over 12 months based on historical trends.",

  // --------------------- Below are entries added for User Manager

  // Product and attribute name constants
  ADDRESS_SEARCH: "Address Search",
  BUILDING_IMAGERY: "Building Imagery",
  PDF_REPORT: "PDF Report",
  CONSTRUCTION_PREFILL: "Construction Prefill",
  COMPARABLES: "Comparables",
  MARKET_VALUATION: "Market Value",
  MARKET_RANGE: "Market Value Range",

  BRAND: "Brand",
  READONLY_PREFILL: "Restrict Edits",
  COMPARABLES_RADIUS: "Search Radius",
  COMPARABLES_NUMBER: "No. of Comparables",
  COMPARABLES_HISTORY: "Search History",

  // user/admin role names
  Role0: "User",
  Role1: "Opta Admin",
  Role2: "Company Admin",
  Role3: "Admin",

  // API error messages
  Error400: "API request failed",
  Error401: "Request authorization failed",
  Error403: "Request not accepted",
  Error404: "API resource not found",
  Error405: "API method not accepted",
  Error408: "Request timeout",
  Error409: "Operation failed due to conflict",
  GroupNameConflict: "Group name already exists",

  // Environmental descriptions
  FLOODSCORE_INFO:
    "Opta Flood Score allows you to instantly identify properties more at risk of flooding (fluvial overland) with real time insight into the relative risk of the property among the broader population. Flood Score is a numeric score the higher the score, the higher the risk.",

  FLOODMAP_INFO:
    "Map imagery helps fully understand flood risks, red areas indicate high-risk area of flood. - Change the view to see 50 years, 100 years and 1000 years.",

  WILDFIRE_INFO:
    "The Fire Underwriters Survey (FUS) wildfire index measures a number of factors that could cause or enhance a wildfire and the risk of wildfire in the area. The Wildfire Index is a numeric score the higher the score, the higher the risk.",

  EARTHQUAKE_INFO:
    "CRESTA Zones (Catastrophe Risk Evaluation and Standardizing Target Accumulations) are part of an international geographic zoning system which help professionals manage natural hazard risk.",

  FLOOD_RISK_VIEW:
    "5km view of flood risk including the probability of a flood loss within 50, 100, and 1000 year range periods.",

  // Flood score not available
  FLOODSCORE_NA:
    "We are unfortunately unable to provide a flood score for this address as it falls outside our mapped flood region.|We can provide a flood score for 98% of Canada's population.",

  FLOODSCORE_CONDO: "Flood Score is not currently available for condo properties.",

  // active wildfires questions
  QUEST_ACTIVE_FIRES: "Are there any 'active fires' within 50km (radius)?",
  QUEST_HOTSPOTS_24: "Are there any 'Fire Hotspots' (in last 24 hrs), within 50km?",
  QUEST_HOTSPOTS_7: "Are there any 'Fire Hotspots (in last 7 days)', within 50km?",
  QUEST_FIRE_PERIMETERS: "Are there any 'fire perimeters' within 50km?",
  QUEST_FIRE_DANGER: "What is the 'Current Fire Danger' rating of area?",

  // flood probability question (with year param)
  QUEST_FLOOD_PROBABILITY: "Probability of flood in next %1 years?",
};

export default labels;
