// Property features form for condo view
import React from "react";
import { useGlobal } from "reactn";
import cx from "classnames";
import useLanguage from "../useLanguage";
import { getProp } from "../../lib/util";
import PropertyFeature from "./PropertyFeature";
import RentalTypeSelect from "./RentalTypeSelect";
import {
  getCondoBuildingFeaturesByColumns,
  getCondoUnitFeaturesByColumns,
} from "../../lib/features-config";

// Features display order for left and right columns
// Unit features:
const unitFeats = getCondoUnitFeaturesByColumns();

// Building features
const buildingFeats = getCondoBuildingFeaturesByColumns();

const CondoFeatures = (props) => {
  const { featuresSchema, currentFeatures: features, formLocked = false } = props;
  const { t } = useLanguage();
  const [hasRental] = useGlobal("PROD_AVAIL_RENTAL_VAL");

  //--------------------------------------------------
  if (!features) return null;
  //--------------------------------------------------

  return (
    <React.Fragment>
      <div className="property-features">
        <h2>{t("Condo Unit Features")}</h2>
        <div className="pf--content">
          {unitFeats.map((keyList, num) => {
            return (
              <div key={num} className={cx("pf--list", `col${num + 1}`, "table")}>
                {keyList.map((formKey, idx) => {
                  return (
                    <PropertyFeature
                      key={idx}
                      formKey={formKey}
                      refValue={features[formKey]}
                      values={getProp(featuresSchema, formKey)}
                      emptyAllowed={true}
                      locked={!!formLocked}
                    />
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
      <div className="property-features">
        <h2>{t("Condo Building Features")}</h2>
        <div className="pf--content">
          {buildingFeats.map((keyList, num) => {
            return (
              <div key={num} className={cx("pf--list", `col${num + 1}`, "table")}>
                {keyList.map((formKey, idx) => {
                  return (
                    <PropertyFeature
                      key={idx}
                      formKey={formKey}
                      refValue={features[formKey]}
                      values={getProp(featuresSchema, formKey)}
                      locked={!!formLocked}
                    />
                  );
                })}
              </div>
            );
          })}
        </div>
        {!!hasRental && (
          <div className="pf--content">
            <RentalTypeSelect />
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default CondoFeatures;
