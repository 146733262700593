import { useEffect, useCallback, useRef } from "react";

const useTimeout = (callback, timeout) => {
  const timerRef = useRef();

  const cancel = useCallback(() => {
    const timer = timerRef.current;
    if (timer) {
      timerRef.current = undefined;
      clearTimeout(timer);
    }
  }, [timerRef]);

  useEffect(() => {
    timerRef.current = setTimeout(callback, timeout);
    return cancel;
  }, [callback, timeout, cancel]);

  return cancel;
};

export default useTimeout;
