import React, { useState, useEffect } from "react";
import { useGlobal } from "reactn";
import Iframe from "react-iframe";
import qs from "query-string";
import { googleMapKey } from "../../config";
import { getHttpClient } from "../../lib/api";
import { digObject, getProp, isNone } from "../../lib/util";
import getLogger from "../../lib/debug-logger";
import ImagePlaceholder from "./PropertyImagePlaceholder";

import "../../styles/avm/street-view-frame.scss";

const log = getLogger("GoogleStreetView", 2);

const googleEmbedUrl = "https://www.google.com/maps/embed/v1/streetview";
const googleMetadataEndpoint = "https://maps.googleapis.com/maps/api/streetview/metadata";

const calculateHeading = (lat, lng, metadata) => {
  const location = digObject(metadata, "data", "location");
  const metaLat = getProp(location, "lat");
  const metaLng = getProp(location, "lng");

  let heading = 0;
  if (isNone(metaLat) || isNone(metaLng)) return heading;

  const same = (x, y) => Number(x).toFixed(6) === Number(y).toFixed(6);
  if (!same(lat, metaLat) || !same(lng, metaLng)) {
    const lat1 = (Number(metaLat) * Math.PI) / 180;
    const lng1 = (Number(metaLng) * Math.PI) / 180;
    const lat2 = (Number(lat) * Math.PI) / 180;
    const lng2 = (Number(lng) * Math.PI) / 180;
    const xAxis =
      Math.cos(lat1) * Math.sin(lat2) - Math.sin(lat1) * Math.cos(lat2) * Math.cos(lng2 - lng1);
    const yAxis = Math.sin(lng2 - lng1) * Math.cos(lat2);
    try {
      heading =
        ((2 *
          Math.atan(yAxis / (Math.sqrt(Math.pow(xAxis, 2) + Math.pow(yAxis, 2)) + xAxis)) *
          180) /
          Math.PI +
          360) %
        360;
    } catch {}
  }
  heading = Math.round(heading);
  log("heading", heading);
  return heading;
};

const getMetada = async (params) => {
  const client = getHttpClient();
  const query = { key: googleMapKey, source: "outdoor", ...params };
  log("metadata query", query);
  const url = qs.stringifyUrl({ query, url: googleMetadataEndpoint });
  const metadata = await client.request({ method: "GET", url });
  log("metadata response", metadata);
  return metadata;
};

const buildUrl = (params) =>
  qs.stringifyUrl({ url: googleEmbedUrl, query: { key: googleMapKey, ...params } });

const flattenAddress = (address) => {
  log("address", address);
  const {
    StreetNumber,
    StreetName,
    StreetType,
    StreetDirection,
    Municipality,
    Province,
    PostalCode = "",
  } = address;
  const line = [
    StreetNumber,
    StreetName,
    StreetType,
    StreetDirection,
    Municipality,
    Province,
    PostalCode,
    "Canada",
  ]
    .filter((s) => !!s)
    .join(" ");
  log("line", line);
  return line;
};

const GoogleStreetView = () => {
  const [address] = useGlobal("ADDRESS_RESP_OBJECT"); // object
  const [geocode] = useGlobal("GEOCODE"); // object {lat, lng}
  const [src, setSrc] = useState("");
  const [showPlaceholder, setShowPlaceholder] = useState(false);

  useEffect(() => {
    const flattenedAddress = flattenAddress(address);

    const getStreetView = async (address, geocode) => {
      log("get street view image using address");
      let metadata = await getMetada({ location: address });
      let pano = getProp(metadata.data, "pano_id");
      if (!!pano) {
        const heading = calculateHeading(geocode.lat, geocode.lng, metadata);
        const url = buildUrl({ heading, location: [geocode.lat, geocode.lng].join(), pano });
        setSrc(url);
        return;
      }

      log("get street view image using geocode");
      metadata = await getMetada({ location: [geocode.lat, geocode.lng].join() });
      pano = getProp(metadata.data, "pano_id");
      if (!!pano) {
        const heading = calculateHeading(geocode.lat, geocode.lng, metadata);
        const url = buildUrl({ heading, location: [geocode.lat, geocode.lng].join(), pano });
        setSrc(url);
        return;
      }

      log("no street view available, using placeholder");
      setShowPlaceholder(true);
    };
    getStreetView(flattenedAddress, geocode);
  }, [address, geocode]);

  return (
    <div className="streetview-wrap">
      {src && <Iframe url={src} className="streetview-frame" />}
      {showPlaceholder && <ImagePlaceholder />}
    </div>
  );
};

export default GoogleStreetView;
