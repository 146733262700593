/* Address Search component based on OSS */
import React, { useState, useEffect, useRef, useCallback } from "react";
import { useDebouncedCallback } from "use-debounce";
import cx from "classnames";
import useLanguage from "../useLanguage";
import useTimeout from "../useTimeout";
import SearchTips from "./SearchTips";
import SearchByLegal from "./SearchByLegal";
import { fetchAddresses } from "../../lib/oss-api";
import { getProp, fixPostal, isPostal, isString, listSize, strval } from "../../lib/util";
import { setGlobalValue } from "../../lib/global-util";

import { elementFromClass } from "../../lib/webapi";

import { makeAddressLine } from "../../lib/oss-util";

import getLogger from "../../lib/debug-logger";

import "../../styles/avm/search-widget.scss";

const log = getLogger("SearchWidget", 2);

const MESSAGE_TIMEOUT = 20000;

// validate address search string
const addrSearchOk = (searchStr) =>
  !!searchStr &&
  isString(searchStr) &&
  (isPostal(searchStr) ||
    /^[\dA-Z-]+.*\s+(?:\d+\s+[A-Z]|[A-Z]{3}).*$/i.test(searchStr.replace("'", "")));

// normalize text for comparizon
const normText = (s) => strval(s).toUpperCase().replace(/\W+/g, " ");

// normalize postal code (ignore case)
const normPostal = (s) => fixPostal(strval(s).toUpperCase());

// storage for returned suggested addresses
let cachedHints = [];

let failedSearches = []; // store failed searches here

// postal code cache
let postalCodeCache = {};

// merge hints obtained from search API with those already cached
const cacheHints = (hints) => {
  log(`caching ${listSize(hints)} suggestions`);
  if (listSize(hints)) {
    cachedHints = [...new Set([...cachedHints, ...hints])]; // merge and remove duplicates
  }
};

// extract cached addresses that start with a fragment
const getCachedHints = (textFrag) => {
  const pref = normText(textFrag);
  return cachedHints.filter((addr) => normText(addr).startsWith(pref));
};

// cache hints by postal code
const cacheByPostalCode = (postalCode, hints) => {
  const key = normPostal(postalCode);
  log(`caching ${listSize(hints)} suggestions by postal code '${key}'`);
  if (key) {
    postalCodeCache[key] = [...hints];
  }
};

// extract addresses cached by postal code
const getCachedByPostalCode = (postalCode) =>
  getProp(postalCodeCache, normPostal(postalCode)) || [];

// combined caching methods

const updateCaches = (searchStr, hints) => {
  isPostal(searchStr) ? cacheByPostalCode(searchStr, hints) : cacheHints(hints);
};

const getFromCache = (searchStr) =>
  isPostal(searchStr) ? getCachedByPostalCode(searchStr) : getCachedHints(searchStr);

const isFailedSearch = (textFrag) => {
  const sample = normText(textFrag);
  for (let s of failedSearches) {
    if (sample.startsWith(s)) return true;
  }
  return false;
};

const storeFailedSearch = (textFrag) => {
  if (isFailedSearch(textFrag)) {
    return; // failed prefix already stored
  }
  failedSearches.push(normText(textFrag));
  log(`failed searches`, failedSearches); //---------------- log
};

const SearchWidget = (props) => {
  const [searchMode, setSearchMode] = useState(0); // 1 - advanced search
  const [inputValue, setInputValue] = useState("");
  const [hints, setHints] = useState([]);
  const [hintIndex, setHintIndex] = useState(0);
  const [message, setMessage] = useState("");

  const { t } = useLanguage();

  const inputRef = useRef(null);

  const hasHints = useCallback(() => !!listSize(hints), [hints]);

  const clearTimer = useTimeout(() => {
    setMessage("");
  }, MESSAGE_TIMEOUT);

  const showSelectedProperty = useCallback(() => {
    if (!addrSearchOk(inputValue)) return;
    log("searching address:", inputValue); //---------------- log
    setGlobalValue("ADDRESS_LINE", inputValue); // this will trigger property view
  }, [inputValue]);

  const selectNextHint = useCallback(
    (reverse = false) => {
      const len = hints.length,
        curIdx = hintIndex;
      if (len < 2) return;
      let nextIdx;
      if (reverse) {
        nextIdx = curIdx - 1 < 0 ? len - 1 : curIdx - 1;
      } else {
        nextIdx = curIdx + 1 < len ? curIdx + 1 : 0;
      }
      setHintIndex(nextIdx);
    },
    [hints, hintIndex, setHintIndex]
  );

  const selectPrevHint = useCallback(() => {
    selectNextHint(true);
  }, [selectNextHint]);

  const dropHints = useCallback(() => {
    if (hints.length) setHints([]);
    if (hintIndex) setHintIndex(0);
  }, [hints, hintIndex, setHints, setHintIndex]);

  const applySelectedHint = useCallback(() => {
    const hint = hints[hintIndex];
    if (!hint) return;
    setInputValue(hint);
    dropHints();
  }, [hints, hintIndex, setInputValue, dropHints]);

  const setFailed = (textFrag) => {
    log(`search for "${textFrag}" has failed`); //------------- log
    setMessage("NoSearchResultsMessage");
  };

  const fetchHints = async (searchStr) => {
    if (!searchStr) {
      return;
    }
    // normalize postal code
    const textFrag = isPostal(searchStr) ? normPostal(searchStr) : fixPostal(searchStr);
    const dataArr = await fetchAddresses(textFrag);
    log(`${listSize(dataArr)} suggestions returned`); // --------------- log
    if (listSize(dataArr)) {
      const addrList = dataArr.map((obj) => makeAddressLine(obj));
      // update caches
      updateCaches(textFrag, addrList);
      setHints(addrList);
    } else {
      storeFailedSearch(textFrag);
      setFailed();
    }
  };

  const resetSearchMode = useCallback(() => {
    if (!!searchMode) setSearchMode(0);
  }, [searchMode, setSearchMode]);

  const resetSearch = useCallback(() => {
    if (searchMode) {
      setSearchMode(0);
    } else {
      dropHints();
      setInputValue("");
    }
  }, [searchMode, setSearchMode, dropHints, setInputValue]);

  // throttle API calls
  const lazyFetch = useDebouncedCallback((val) => {
    fetchHints(val);
  }, 999);

  // text input event handler
  const handleInputChange = (ev) => {
    const val = ev.target.value;
    if (val === inputValue) {
      return;
    }
    dropHints();
    setInputValue(val);
    // check if this search already failed
    if (isFailedSearch(val)) {
      setFailed(val);
      return;
    }
    // validate search string
    if (!addrSearchOk(val)) {
      return;
    }
    // check if cached addresses matching the search string exist
    const addrList = getFromCache(val);
    if (listSize(addrList)) {
      log(`applying ${addrList.length} cached addresses`); // -------------- log
      setHints(addrList);
      return;
    }
    // call API
    lazyFetch(val);
    log(val); //------------------ log
  };

  // clearing message timeout
  useEffect(() => {
    if (!message) clearTimer();
  });

  // reset state on search mode change
  useEffect(() => {
    if (searchMode) {
      dropHints();
      setInputValue("");
    }
  }, [searchMode, dropHints, setInputValue]);

  // keyboard and click listeners
  useEffect(() => {
    if (searchMode) return;

    const keyHandler = (ev) => {
      switch (ev.code) {
        case "Enter":
          listSize(hints) ? applySelectedHint() : showSelectedProperty();
          break;
        case "ArrowDown":
          selectNextHint();
          break;
        case "ArrowUp":
          selectPrevHint();
          break;
        case "Escape":
          resetSearch();
          break;
        default:
          setMessage("");
      }
    };
    const pageClickHandler = (ev) => {
      if (!elementFromClass(ev.target, ["hints-dropdown", "input-combo", "advanced-search-box"])) {
        resetSearch();
      }
    };

    window.addEventListener("keydown", keyHandler);
    window.addEventListener("click", pageClickHandler);
    inputRef.current.focus();

    return () => {
      window.removeEventListener("keydown", keyHandler);
      window.removeEventListener("click", pageClickHandler);
    };
  }, [
    searchMode,
    hints,
    applySelectedHint,
    resetSearch,
    selectNextHint,
    selectPrevHint,
    showSelectedProperty,
  ]);

  const handleAdvanced = useCallback(() => {
    log("selecting Advanced search"); //---------------------- log
    setSearchMode(1);
  }, [setSearchMode]);

  // Search by Legal callback
  const doSearchByLegal = (obj) => {
    log("Doing search by legal description"); //------------------- log
  };

  return (
    <div className={cx("search-widget", props.className)}>
      <div className="input-combo">
        <div className="input-box">
          <input
            tabIndex={props.tabIndex || 1}
            type="text"
            value={inputValue}
            className={cx("text-input", "search-input", {
              blank: !inputValue,
            })}
            ref={inputRef}
            placeholder={t("EnterAddress")}
            onChange={handleInputChange}
            onFocus={resetSearchMode}
          />
          <button
            className="search-icon search-btn"
            disabled={!inputValue}
            onClick={() => showSelectedProperty()}
          ></button>
        </div>

        {hasHints() && (
          <div className="hints-dropdown">
            {hints.map((addr, idx) => {
              return (
                <div
                  key={idx}
                  className={cx("hint-item", { current: hintIndex === idx })}
                  onClick={() => applySelectedHint()}
                  onMouseEnter={() => setHintIndex(idx)}
                >
                  {addr}
                </div>
              );
            })}
          </div>
        )}
      </div>
      {!hasHints() && <SearchTips />}

      {!hasHints() && !!message && <div className="search-message">{t(message)}</div>}

      {!hasHints() && !message && !searchMode && (
        <div className="advanced-search-handle">
          <button className={cx("big-button")} onClick={handleAdvanced}>
            {t("Advanced")}
          </button>
        </div>
      )}
      {searchMode === 1 && <SearchByLegal searchAction={doSearchByLegal} />}
    </div>
  );
};

export default SearchWidget;
