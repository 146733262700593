// store last visited unique addresses

import storage from "./localstorage";
import { isString } from "./util";
import getLogger from "./debug-logger";
import config from "../config";

const log = getLogger("address-cache", 3);

const { addressCacheOn, addressCacheSize } = config;
const CACHE_KEY = "addressStack";

const getCache = () => storage.get(CACHE_KEY) || [];

const saveCache = (addrList) => storage.set(CACHE_KEY, addrList);

export const dropAddresses = () => storage.remove(CACHE_KEY);

// list street addresses (and remove previously used JSON objects)
export const listAddresses = () =>
  addressCacheOn ? getCache().filter((it) => it && isString(it) && !it.startsWith("{")) : [];

export const addressSaved = (addr) => getCache().includes(addr);

// put new or existing address on top of the list
export const saveAddress = (addr) => {
  // discard saved addresses if cache is disabled
  if (!addressCacheOn) {
    dropAddresses();
    return;
  }
  let cache = getCache().filter((it) => isString(it) && it !== addr); // remove address if already there or not a string;
  log("storing address in pocket", addr);
  cache = [addr, ...cache];
  saveCache(cache.slice(0, addressCacheSize)); // truncate to max size and save
};

export const removeAddress = (addr) => {
  const cache = getCache().filter((it) => it !== addr);
  saveCache(cache);
};
