// Construction features list item
import React, { useCallback, useEffect, useState } from "react";
import { useGlobal } from "reactn";
import cx from "classnames";
import useLanguage from "../useLanguage";
import TextInput from "./TextInput";
import Dropdown from "./Dropdown";
import {
  listSize,
  isNone,
  isNumeric,
  strval,
  digObject,
  jsonParse,
  sameString,
  getProp,
  strlen,
} from "../../lib/util";
import { setGlobalValue, getGlobalValue } from "../../lib/global-util";
import getLogger from "../../lib/debug-logger";

const log = getLogger("PropertyFeature", 4);

// cast feature's type from string to original
const fixFeatureType = (key, val) => {
  const typeHint = digObject(getGlobalValue("FEATURES_CONF"), key, "dataType");
  if (val === "" || !typeHint || typeHint === "string") return val;
  if (typeHint === "list") return val.split("|");
  if (typeHint === "json") return jsonParse(val);
  if (typeHint === "boolean") return val !== "false";
  if (typeHint === "number") return Number(val) || 0;
  return val;
};

const trackFeatureChange = (key, val) => {
  const snapshot = getGlobalValue("FEATURES_CURRENT");
  const lastSavedFeats = snapshot ? jsonParse(snapshot) : null;
  if (!lastSavedFeats) {
    return;
  }
  const refVal = getProp(lastSavedFeats, key);
  const changed = !sameString(refVal, val);
  let keyset = new Set(getGlobalValue("DIRTY_KEYS") || []);
  if (keyset.has(key)) {
    if (!changed) keyset.delete(key);
  } else {
    if (changed) keyset.add(key);
  }
  const keylist = !keyset.size ? null : [...keyset];
  setGlobalValue("DIRTY_KEYS", keylist);
};

//----------------------------------------------------------
// Internal feature value component
//----------------------------------------------------------
const PropertyFeatureValue = (props) => {
  const {
    formKey,
    refValue,
    config,
    title,
    values = null,
    emptyAllowed = true,
    locked = false,
  } = props;

  const {
    pattern = "",
    minValue,
    maxValue,
    maxLength = 10,
    numeric = false,
    dataType = "",
  } = config || {};

  const [dataValue, setDataValue] = useGlobal(formKey);
  const [readonly] = useGlobal("READONLY_PREFILL");

  const { t } = useLanguage();

  // translate data value into text label
  const valueToLabel = useCallback(
    (val) => {
      if (val === "") return "";
      if (val === null) return "-";
      if (isNumeric(val)) return strval(val);
      if (val + "" === "true") return t("Yes");
      if (val + "" === "false") return t("No");
      return t(val);
    },
    [t]
  );

  // tracking updated construction features
  useEffect(() => {
    trackFeatureChange(formKey, dataValue);
  }, [formKey, dataValue]);

  const handleChange = useCallback(
    (strValue) => {
      const val = fixFeatureType(formKey, strValue);
      log(`setting ${formKey} to '${val}'`); //----------------- log
      setDataValue(val);
    },
    [formKey, setDataValue]
  );

  const commonClass = "feature-value";

  //---------------------------------------------
  // display readonly value or missing data point
  //---------------------------------------------
  if (readonly || isNone(dataValue)) {
    return (
      <span
        className={cx(commonClass, "value-readonly", {
          nodata: isNone(dataValue),
          dirty: dataValue && dataValue !== refValue,
        })}
        title={title}
      >
        {valueToLabel(dataValue)}
      </span>
    );
  }

  //--------------------------------------------------------------------------
  // Render drop-down selector if values present
  //--------------------------------------------------------------------------
  if (listSize(values)) {
    let vals = emptyAllowed ? ["", ...values] : [...values];
    // remove '0' from car number or add empty option (for 'not entered')
    if (formKey === "GarageNumberOfCars") vals = vals.filter((v) => strval(v) !== "0");

    const labels = vals.map((v) => valueToLabel(v));

    return (
      <Dropdown
        className={commonClass}
        initValue={dataValue}
        refValue={refValue}
        vals={vals}
        labels={labels}
        placeholder={t("Not entered")}
        changeCallback={handleChange}
        disabled={locked}
        {...props}
      />
    );
  }

  //---------------------------------------------
  // text input
  //---------------------------------------------

  return (
    <TextInput
      name={formKey}
      className={cx(commonClass, "value-text")}
      dataValue={dataValue}
      refValue={refValue}
      minValue={minValue}
      maxValue={maxValue}
      pattern={pattern}
      numeric={numeric || dataType === "number"}
      maxLength={maxLength}
      changeCallback={handleChange}
      disabled={locked}
    />
  );
};

//----------------------------------------------------------
// Construction feature as label + value/input/selector
//----------------------------------------------------------
const PropertyFeature = (props) => {
  const { formKey } = props;

  const { t } = useLanguage();
  const [config] = useGlobal("FEATURES_CONF");
  const [lang] = useGlobal("LANGUAGE");

  // construction feats sources (exist for logged in as admin only)
  const [featSources] = useGlobal("FEATURES_SOURCES");

  const [featConf, setFeatConf] = useState(null);
  const [featSource, setFeatSource] = useState("");
  const [showSource, setShowSource] = useState(false);
  // error indicating the data source code is not mapped to a single letter
  const [sourceError, setSourceError] = useState(false);

  useEffect(() => {
    setFeatConf(getProp(config, formKey));
  }, [config, formKey, setFeatConf]);

  useEffect(() => {
    setShowSource(!!featSources);
  }, [featSources, setShowSource]);

  useEffect(() => {
    //setShowSource(!!featSources);
    if (!featSources) return;
    let marker = getProp(featSources, formKey, "");
    if (strlen(marker) > 2) setSourceError(true); // not mapped to one/two letters
    setFeatSource(marker);
  }, [featSources, formKey, setFeatSource]);

  return (
    <div className="table-row feature-item">
      <div className={cx("feature-label", "table-cell", lang)} title={t(formKey)}>
        {t(formKey)}
      </div>
      <div className="table-cell">
        <PropertyFeatureValue config={featConf} {...props} />
      </div>
      {showSource && !!featSource && (
        <div className="table-cell">
          <div className={cx("feature-source", { error: !!sourceError })}>
          {featSource}
        </div>
        </div>
        
      )}
    </div>
  );
};

export default PropertyFeature;
