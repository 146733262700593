// transform OSS datasets
import {
  getProp,
  getPropObject,
  getTreeProp,
  digObject,
  isObject,
  isArray,
  jsonStringify,
  strval,
  listSize,
  hasProp,
  inArray,
  copyProps,
  join,
  jsonParse,
  makeObject,
  round,
  isNone,
  isEmpty,
  mergeJson,
  makeShortDateStamp,
} from "./util";
import { getGlobal, getGlobalValue } from "./global-util";
import {
  getConstructionFeaturesKeys,
  initFeaturesConfig,
  isCondo,
  isResidential,
} from "./features-config";
import { makeAddrCity, makeAddrStreet, makeAddressLine } from "./oss-util";
import { saveAddress } from "./address-cache";
import getLogger from "./debug-logger";

const log = getLogger("oss-transform", 3);

//------------------------------------------------------------
// extract all products from dataset
//------------------------------------------------------------
const getAllProducts = (respData) => getTreeProp(respData, "ProductResults");

//------------------------------------------------------------
// extract general response error
//------------------------------------------------------------
const getResponseError = (respData) =>
  digObject(respData, "ResponseBody", "Result", 0, "Message", 0) ||
  digObject(respData, "Fault", "faultstring") ||
  "";

//------------------------------------------------------------
// extract a single product data -- on error it's under nested second prodKey
//------------------------------------------------------------
const getProduct = (respData, prodDataKey) => {
  const product = getTreeProp(getAllProducts(respData), prodDataKey);
  log(`extracting ` + prodDataKey, product === "" ? "''" : product); //-------------------------- log
  // on error there's a nested property with same product key
  return product && hasProp(product, prodDataKey) ? product[prodDataKey] : product;
};
//------------------------------------------------------------
// check product request status
//------------------------------------------------------------
const getProductStatus = (prodData) => getProp(prodData, "StatusCode");

//------------------------------------------------------------
// message explaining missing product data
//------------------------------------------------------------
const getProductMessage = (prodData) => {
  const msg = digObject(prodData, "Messages", "Message"); // if exists, it's likely an array with 1 entry
  log("product message", msg); //---------------- log
  return isArray(msg) ? msg.join(", ") : strval(msg);
};

//------------------------------------------------------------
// product data, status and message bundled in single object
//------------------------------------------------------------
const getProductInfo = (prodData, ...nestedKeys) => {
  if (!isObject(prodData)) return null;
  const data = digObject(prodData, ...nestedKeys);
  const status = getProductStatus(prodData);
  const message = getProductMessage(prodData);
  return { data, status, message };
};

//------------------------------------------------------------
// extract address search result list
//------------------------------------------------------------
export const getAddresses = (addrSearchRespData) => {
  const product = getProduct(addrSearchRespData, "AddressSearch");
  return getProp(product, "Address");
};

//----------------------------------------------------------
// Process structured address returned by OSS
//----------------------------------------------------------
const processDataAddress = (respData, collector = {}) => {
  const addrObj = getTreeProp(respData, "Address");
  log("processing property address object", addrObj); //-------------------- log

  collector.ADDRESS_RESP_OBJECT = addrObj;
  collector.OAK = getProp(addrObj, "OptaAddressKey");

  const { Latitude: lat, Longitude: lng } = getPropObject(addrObj, "Geocode") || {};
  if (lat && lng) collector.GEOCODE = { lat, lng };
  //
  // extract municipal code
  collector.MUNICIPAL_CODE = (() => {
    const geoClass = getProp(addrObj, "GeographicalClassification");
    if (!listSize(geoClass)) return "";
    for (let item of geoClass) {
      if (getProp(item, "ClassificationType") === "Municipal Code") {
        return getProp(item, "ClassificationID");
      }
    }
    return "";
  })();
  // additional information from Address
  collector.ADDRESS_LEGAL_DESCRIPTION = getTreeProp(addrObj, "UnformattedLegalDescription");
  collector.ADDRESS_PID = getTreeProp(addrObj, "PropertyIdentificationNumber");
  //
  // setting Rental valuation items' visibility by product availability and applicable provinces
  const province = getProp(addrObj, "Province"); // 2-char code, in uppercase
  collector.SHOW_RENTAL = !!["ON", "BC", "QC", "AB"].includes(province);
  //
  // extract street and city parts of property address from resonse
  const addrStreet = makeAddrStreet(addrObj);
  const addrCity = makeAddrCity(addrObj);

  collector.ADDRESS_LINE_STREET = addrStreet;
  collector.ADDRESS_LINE_CITY = addrCity;

  // side effect: save valid address in the pocket
  const streetAddr = makeAddressLine([addrStreet, addrCity]);

  log("street address extracted", streetAddr); //----------------------- log
  saveAddress(streetAddr); // save in cache
  return collector;
};

//----------------------------------------------------------
// Process extracted products, populate state variables
//----------------------------------------------------------
const populateProducts = (respData, productList, collector = {}) => {
  const expected = (key) => inArray(productList, key);

  // response error
  let errMsg = getResponseError(respData);

  if (errMsg) {
    collector.ERROR = "DATA_ERROR";
    collector.PREFILL_ERROR = "PREFILL_ERROR";
    collector.IMAGERY_ERROR = "IMAGERY_ERROR";
    log(`general error`, errMsg); //-------------- log
    return collector;
  }

  let prodData; // reusable data container

  let isInitialCall = !getGlobalValue("ADDRESS_RESP_OBJECT");
  let isValuationCall = false;

  // try construction features first: further transformations may depend on this data
  if (expected("iClarifyFinancialResidentialPrefill")) {
    isInitialCall = true;

    prodData = getProduct(respData, "iClarifyFinancialResidentialProduct");

    processConstructionFeatures(prodData, collector);
  }
  //
  //--------- try imagery
  if (expected("BuildingImagery")) {
    prodData = getProduct(respData, "BuildingImagery");
    processImages(prodData, collector);
  }
  //
  //--------- try confidence rating
  if (expected("MarketValuationConfidence")) {
    prodData = getProduct(respData, "MarketValuationConfidenceProduct");
    processConfidence(prodData, collector);
  }
  //
  //--------- try valuation
  if (expected("ResidentialMarketValuation")) {
    isValuationCall = true;
    collector.AVM_ERROR = errMsg || null;
    prodData = getProduct(respData, "ResidentialMarketValuationProduct");
    processValuation(prodData, collector);
  }
  //--------- try value range
  if (expected("EstimatedValueRange")) {
    isValuationCall = true;
    collector.AVM_ERROR = errMsg || null;
    prodData = getProduct(respData, "EstimatedValueRangeProduct");
    processRange(prodData, collector);
  }
  //
  //--------- try rental
  if (expected("RentalValuation")) {
    isValuationCall = true;
    prodData = getProduct(respData, "RentalValuationProduct");
    log(`processing rental valuation`, prodData); //----------- log
    processRental(prodData, collector);
  }
  //
  //--------- try PDF Report URL (must be done after valuation)
  if (expected("iClarifyFinancialResidentialReport")) {
    prodData = getProduct(respData, "iClarifyResidentialReportProduct");

    // set PDF Report URL (must be empty string on failure)
    const reportUrl = digObject(prodData, "Report", "Content", "ContentURL") || "";
    if (!reportUrl) collector.REPORT_URL = "REPORT_ERROR";
    collector.REPORT_URL = reportUrl;
  }
  //
  //--------- try listing (Valuation+)
  if (expected("PropertyListings")) {
    prodData = getProduct(respData, "PropertyListingsProduct");
    log(`processing property listing`, prodData); //----------- log
    processListing(prodData, collector);
  }
  //
  //--------- try sales (Valuation+)
  if (expected("PropertySales")) {
    prodData = getProduct(respData, "PropertySalesProduct");
    log(`processing property sales`, prodData); //----------- log
    processSales(prodData, collector);
  }
  //
  //--------- try Assessments (Valuation+)
  if (expected("AssessmentListings")) {
    prodData = getProduct(respData, "AssessmentListingsProduct");
    log(`processing property assessments`, prodData); //----------- log
    processAssessments(prodData, collector);
  }
  //
  //--------- try Rentals (Valuation+)
  if (expected("RentalListings")) {
    prodData = getProduct(respData, "RentalListingsProduct");
    log(`processing property rentals`, prodData); //----------- log
    processRentals(prodData, collector);
  }
  //
  //--------- try Property Stats (Valuation+)
  // some property stats for Residential are combined with Property Details
  if (expected("PropertyStats")) {
    prodData = getProduct(respData, "PropertyStatsProduct");
    log(`processing property stats`, prodData); //----------- log
    processStats(prodData, collector);
  }
  //
  //--------- try Permits (Valuation+)
  // some property stats for Residential are combined with Property Details
  if (expected("OptaPermitFinancial")) {
    prodData = getProduct(respData, "OptaPermitFinancialProduct");
    log(`processing property permits`, prodData); //----------- log
    processPermits(prodData, collector);
  }
  //
  //--------- try Neighbourhood Name
  if (expected("NeighbourhoodName")) {
    prodData = getProduct(respData, "NeighbourhoodNameProduct");
    log(`processing Neighbourhood Name`, prodData); //----------- log
    processNeighbourhood(prodData, collector);
  }
  //
  //--------- try Market Metrics
  if (expected("MarketMetrics")) {
    prodData = getProduct(respData, "MarketMetricsProduct");
    log(`processing Market Metrics`, prodData); //----------- log
    processMetrics(prodData, collector);
  }
  //--------- try Historical Price Index
  if (expected("HPIHistorical")) {
    prodData = getProduct(respData, "HPIHistoricalProduct");
    log(`processing Historical Price Index`, prodData); //----------- log
    processHpi(prodData, collector);
  }
  //--------- try PDF Snapshot URL
  if (expected("iClarifySnapshotReport")) {
    prodData = getProduct(respData, "iClarifySnapshotReportProduct");
    // set PDF Report URL (must be empty string on failure)
    collector.SNAPSHOT_URL = digObject(prodData, "Report", "Content", "ContentURL") || "";
  }
  //--------- try Environmental products
  // FloodScore
  if (expected("FloodScore")) {
    prodData = getProduct(respData, "FloodScoreProduct");
    processFloodScore(prodData, collector);
  }

  //-------------------------------------------------------------
  if (isInitialCall) {
    processDataAddress(respData, collector);
    // blend in property stats into property details for residential
    if (
      isResidential(collector.PROPERTY_TYPE) &&
      collector.FEATURES_INITIAL &&
      collector.STATS_INITIAL
    ) {
      collector.FEATURES_INITIAL = collector.FEATURES_CURRENT = mergeJson(
        collector.FEATURES_INITIAL,
        collector.STATS_INITIAL
      );
    }
  }
  // final transformations: update reference snapshots
  if (isValuationCall || isInitialCall) {
    updateValuationSnapshot(collector, isInitialCall);
  }

  updateFeaturesSnapshot(collector);

  log(`transformed data`, collector); //-------------- log

  return collector;
};

const updateValuationSnapshot = (collector, isInitialCall) => {
  const valueKeys = [
    "AVM_VALUE",
    "CONFIDENCE_RATE",
    "AVM_RANGE_LOW",
    "AVM_RANGE_HIGH",
    "RENTAL_RANGE",
    "RENTAL_VALUE",
  ];
  let valueObj = makeObject(valueKeys, "");

  let jsonSnapshot = getGlobalValue("VALUATION_SNAPSHOT");
  if (isInitialCall) {
    Object.assign(valueObj, copyProps(valueKeys, collector));
    collector.VALUATION_INITIAL = collector.VALUATION_SNAPSHOT = jsonStringify(valueObj);
    log(`initial data object`, valueObj); //---------------- log
  } else if (jsonSnapshot) {
    // update valuation reference snapshot
    valueObj = { ...jsonParse(jsonSnapshot), ...copyProps(valueKeys, collector) };
    collector.VALUATION_SNAPSHOT = jsonStringify(valueObj);
  }
  return collector;
};

//----------------------------------------------------------
// updating property details after editing
//----------------------------------------------------------
const updateFeaturesSnapshot = (collector) => {
  const unsavedKeys = getGlobalValue("DIRTY_KEYS");
  let featSnapshot = getGlobalValue("FEATURES_CURRENT");
  if (!listSize(unsavedKeys) || !featSnapshot) {
    return collector;
  }
  let currentFeats = { ...jsonParse(featSnapshot), ...copyProps(unsavedKeys, getGlobal()) };

  log(`updating current features snapshot`, currentFeats); //---------------- log

  featSnapshot = jsonStringify(currentFeats);
  Object.assign(collector, {
    DIRTY_KEYS: null,
    FEATURES_CURRENT: featSnapshot,
  });
  return collector;
};

//----------------------------------------------------------
// transform construction features into a plain object
// (from iClarifyFinancialResidentialProduct)
//----------------------------------------------------------
const transformFeats = (featProduct, propType) => {
  let container;
  if (isCondo(propType)) {
    const obj = getProp(featProduct, "MultiResidentialBuilding");
    const building = getProp(obj, "BuildingFeatures");
    const unit = getProp(obj, "UnitFeatures");
    container = { ...building, ...unit };
  } else {
    container = { ...getProp(featProduct, "ResidentialBuilding") };
  }
  // arrange features as configured
  const featKeys = getConstructionFeaturesKeys(propType) || [];
  return copyProps(featKeys, container);
};

//----------------------------------------------------------
// Populate Residential or Condominium construction features
//----------------------------------------------------------
const processConstructionFeatures = (product, collector = {}) => {
  const info = getProductInfo(product, "Property");
  const data = getProp(info, "data");

  // in case of errors set error message as placeholder for translations
  const setPrefillError = () => (collector.PREFILL_ERROR = "PREFILL_ERROR");

  if (!data) {
    setPrefillError();
    log(`Error processing construction features:`, getProp(info, "message") || "No prefill data"); //------------- log
    return collector;
  }
  const propType = getProp(info.data, "PropertyType");

  if (!propType) {
    setPrefillError();
    log(`Error processing construction features: missing property type`); //------------- log
    return collector;
  }

  // extract construction features and collect them in a plain object
  let featData = transformFeats(data, propType);

  // collect missing  data (nulls) and convert them to empty strings
  const missingKeys = [];
  for (let k in featData) {
    if (isNone(featData[k])) {
      missingKeys.push(k);
      featData[k] = "";
    }
  }
  collector.FEATURES_MISSING = missingKeys.join(); // make comma-separated list
  //
  const features = { PROPERTY_TYPE: propType }; // <- plain object holding actual construction features
  const meta = {}; // <- object holding features' configuration
  const featConf = initFeaturesConfig(propType); // base config map

  for (let dataKey in featConf) {
    let conf = { ...featConf[dataKey] }; // extending original configuration
    let featValue = getProp(featData, dataKey);

    // form inputs are strings, but have to be converted to original type on submit
    conf.dataType = typeof featValue; // store original type in feature config

    // convert decimals to integers
    if (["LotDepth", "LotFrontage"].includes(dataKey)) featValue = round(featValue);

    features[dataKey] = featValue;
    meta[dataKey] = conf;
  }
  //
  if (hasProp(features, "GarageNumberOfCars") && Number(features.GarageNumberOfCars) === 0) {
    features.GarageNumberOfCars = "";
  }
  // create/update reference data objects
  collector.FEATURES_CONF = meta;

  // create serialized features snapshots (property stats may be added later)
  const featsJson = jsonStringify(features);
  log(`features initial snapshot`, features); //--------- log
  collector.FEATURES_PREFILL = collector.FEATURES_INITIAL = collector.FEATURES_CURRENT = featsJson;
  //collector.FEATURES_CURRENT = featsJson;

  // drop data error, if already set
  collector.PREFILL_ERROR = null;

  // data timestamp
  collector.PROPERTY_TIME = Date.now();

  // construction features as separate global state vars
  Object.assign(collector, features);

  return collector;
};

const valuationError = (collector = {}) => {
  collector.AVM_ERROR = collector.MESSAGE = "AVM_ERROR";
  collector.AVM_FAILED = true;
  return collector;
};

//----------------------------------------------------------
// Process market value
//----------------------------------------------------------
const processValuation = (product, collector = {}) => {
  if (!product) {
    return valuationError(collector);
  }
  const info = getProductInfo(product, "MarketValuation", 0);
  const { data, message } = info || {};

  const avmValue = getProp(data, "Value");
  log("processing MarketValuation", avmValue); //------------- log
  if (!data || !avmValue) {
    log(`MarketValuation error:`, message || "AVM_ERROR"); //--------- log

    return valuationError(collector);
  }
  collector.AVM_VALUE = avmValue;
  if (!getGlobalValue("AVM_DESCRIPTION")) {
    collector.AVM_DESCRIPTION = getProp(data, "ValueDescription"); // explainability, optional
  }

  return collector;
};

//----------------------------------------------------------
// Process value range
//----------------------------------------------------------
const processRange = (product, collector = {}) => {
  if (!product) {
    return valuationError(collector);
  }
  const info = getProductInfo(product, "EstimatedValueRange", 0);
  const { data, message } = info || {};
  const lowValue = getProp(data, "LowRangeValue");
  log("processing EstimatedValueRange"); //------------- log
  if (!data || !lowValue) {
    log(`EstimatedValueRange error:`, message || "AVM_ERROR"); //--------- log
    return valuationError(collector);
  }
  collector.AVM_RANGE_HIGH = getProp(data, "HighRangeValue");
  collector.AVM_RANGE_LOW = lowValue;

  return collector;
};

//----------------------------------------------------------
// Process rental value/range
//----------------------------------------------------------
const processRental = (product, collector = {}) => {
  if (!product) {
    return valuationError(collector);
  }
  const info = getProductInfo(product, "RentalValuation", 0);
  const { data, message } = info || {};
  const rentalValue = getProp(data, "RentalValue");
  const rangeValue = getTreeProp(data, "RangeValue");
  log("processing RentalValuation", rentalValue, rangeValue); //------------- log
  if (!data || !listSize(rangeValue)) {
    log(`RentalValuation error:`, message || "RENTAL_VALUE_ERROR"); //--------- log
    if (!product) {
      return valuationError(collector);
    }
  }
  collector.RENTAL_VALUE = rentalValue;
  collector.RENTAL_RANGE = rangeValue.map((it) => getProp(it, "Value"));

  return collector;
};

//----------------------------------------------------------
// Process Confidence rate
//----------------------------------------------------------
const processConfidence = (product, collector = {}) => {
  const info = getProductInfo(product, "MarketValuationConfidence");
  if (!info) {
    collector.CONFIDENCE_ERROR = "No confidence data";
    return collector;
  }
  log("processing confidence rate", info); //------------- log
  collector.CONFIDENCE_RATE = getTreeProp(info.data, "ConfidenceRating") || "";
  if (info.message) {
    collector.CONFIDENCE_ERROR = collector.MESSAGE = info.message;
  }

  return collector;
};

//----------------------------------------------------------
// Process property listing
//----------------------------------------------------------
const processListing = (product, collector = {}) => {
  const info = getProductInfo(product, "PropertyListings", 0, "PropertyValue");
  if (!info) {
    collector.LISTINGS_ERROR = "LISTINGS_ERROR";
    return collector;
  }
  if (info.data) {
    collector.PROPERTY_LISTINGS = info.data;
    log(`property Listings`, info.data); //---------------- log
  }
  if (info.message) {
    collector.LISTINGS_ERROR = collector.MESSAGE = "LISTINGS_ERROR";
  }
  return collector;
};

//----------------------------------------------------------
// Process property sales
//----------------------------------------------------------
const processSales = (product, collector = {}) => {
  const info = getProductInfo(product, "PropertySales", 0, "PropertyValue");
  if (!info) {
    collector.SALES_ERROR = "SALES_ERROR";
    return collector;
  }
  if (info.data) {
    collector.PROPERTY_SALES = info.data;
    log(`property Sales`, info.data); //---------------- log
  }
  if (info.message) {
    collector.SALES_ERROR = collector.MESSAGE = "SALES_ERROR";
  }
  return collector;
};

//----------------------------------------------------------
// Process assessments listings
//----------------------------------------------------------
const processAssessments = (product, collector = {}) => {
  const info = getProductInfo(product, "AssessmentListings", 0, "PropertyValue");
  if (!info) {
    collector.ASSESSMENTS_ERROR = "ASSESSMENTS_ERROR";
    return collector;
  }
  if (info.data) {
    collector.PROPERTY_ASSESSMENTS = info.data;
    log(`property Assessments`, info.data); //---------------- log
  }
  if (info.message) {
    collector.ASSESSMENTS_ERROR = collector.MESSAGE = "ASSESSMENTS_ERROR";
  }
  return collector;
};

//----------------------------------------------------------
// Process rentals listings
//----------------------------------------------------------
const processRentals = (product, collector = {}) => {
  const info = getProductInfo(product, "RentalListings", 0, "PropertyValue");
  if (!info) {
    collector.RENTALS_LIST_ERROR = "RENTALS_LIST_ERROR";
    return collector;
  }
  if (info.data) {
    collector.PROPERTY_RENTALS = info.data;
    log(`property Rentals`, info.data); //---------------- log
  }
  if (info.message) {
    collector.RENTALS_LIST_ERROR = collector.MESSAGE = "RENTALS_LIST_ERROR";
  }
  return collector;
};

//----------------------------------------------------------
// Process property stats
//----------------------------------------------------------
const processStats = (product, collector = {}) => {
  // initial data call - extract stats
  const info = getProductInfo(product, "PropertyStats");
  // populate stats with default values
  let stats = {
    PropertyOccupancy: "Unknown",
    Tenure: "Unknown",
    ZoningDescription: "Unknown",
    RentalActivity: "",
  };
  const respStats = digObject(info, "data", 0);
  if (isEmpty(respStats)) {
    log(`property Stats error`, info); //---------------- log
    collector.STATS_ERROR = "STATS_ERROR";
  } else {
    Object.assign(stats, respStats);
  }
  Object.assign(collector, stats); // globalize individual stats
  const statsJson = jsonStringify(stats);
  collector.STATS_INITIAL = collector.STATS_UPDATED = statsJson;
  return collector;
};

//----------------------------------------------------------
// Process Permits
//----------------------------------------------------------
const processPermits = (product, collector = {}) => {
  if (isObject(product)) {
    const permits = getProp(product, "Permit");
    if (!isArray(permits)) {
      log("OptaPermitFinancial product is not available"); //------------- log
    } else {
      collector.PROPERTY_PERMITS_JSON = jsonStringify(permits);
    }
  }
  return collector;
};

//----------------------------------------------------------
// Process Neighbourhood Name
//----------------------------------------------------------
const processNeighbourhood = (product, collector = {}) => {
  collector.NEIGHBOURHOOD_NAME = getProp(product, "NeighbourhoodName");
  return collector;
};

//----------------------------------------------------------
// Process Market Metrics
//----------------------------------------------------------
const processMetrics = (product, collector = {}) => {
  const info = getProductInfo(product, "MarketMetrics");
  if (!info) {
    collector.METRICS_ERROR = "Market metrics data error";
    return collector;
  }
  if (listSize(info.data) && info.data[0]) {
    const metricsObj = info.data[0];
    log(`Market Metrics`, metricsObj); //---------------- log
    collector.MARKET_METRICS_JSON = jsonStringify({ ...metricsObj });
  } else {
    collector.METRICS_ERROR = info.message || "No Market Metrics data";
  }
  return collector;
};

//----------------------------------------------------------
// Process HPIHistorical
//----------------------------------------------------------
const processHpi = (product, collector = {}) => {
  const info = getProductInfo(product, "HPIHistorical");
  if (!info) {
    collector.HPI_ERROR = "HPIHistorical data error";
    return collector;
  }
  let dataList;
  if (
    listSize(info.data) &&
    info.data[0] &&
    (dataList = info.data[0].PropertyValue) &&
    listSize(dataList)
  ) {
    //const hpiObj = info.data[0];
    log(`HPIHistorical data list`, dataList); //---------------- log
    const mapped = dataList
      .map((it) => {
        if (it.Value && !it.ValueDate) it.ValueDate = makeShortDateStamp();
        return [it.ValueDate, it.Value];
      })
      .sort((a, b) => (a[0] < b[0] ? -1 : 1));
    log(`HPIHistorical data list processed`, mapped); //---------------- log
    collector.HPI_HISTORICAL_JSON = jsonStringify(mapped);
  } else {
    collector.HPI_ERROR = info.message || "No HPIHistorical data";
  }
  return collector;
};

//----------------------------------------------------------
// Process/select images
//----------------------------------------------------------
const processImages = (product, collector = {}) => {
  const info = getProductInfo(product, "Image");
  const images = getProp(info, "data"); // array of objects expected
  if (!listSize(images)) {
    collector.IMAGERY_ERROR = "IMAGERY_ERROR";
    return collector;
  }
  log("processing images"); //------------- log
  let streetUrl = "";
  let mapUrl = "";

  info.data.forEach((it) => {
    let { BestPhoto: best = "", ImageTypeCode: type = "", ImageURL: url = "" } = it;
    if (!url || !type) return;
    if (type === "Street" && (!streetUrl || best === "true")) {
      streetUrl = url;
    } else if (type.includes("Satellite") && (!mapUrl || best === "true")) {
      mapUrl = url;
    }
  });
  collector.IMAGE_STREET = streetUrl;
  collector.IMAGE_MAP = mapUrl;

  if (streetUrl || mapUrl) collector.IMAGERY_LOADED = true;

  return collector;
};

//----------------------------------------------------------
// initial property data transformation
//----------------------------------------------------------
export const transformPropertyData = (respData, productList) => {
  const retObj = { ERROR: "Data Error" };
  if (!respData) {
    log(`Transformation error, no data provided`, respData); //------------- log
    return retObj;
  }
  try {
    log(`transforming products`, join(productList, ", ")); //--------------------- log
    const dataObj = populateProducts(respData, productList);
    if (isObject(dataObj)) {
      delete retObj.ERROR;
      Object.assign(retObj, dataObj);
      return retObj;
    }
  } catch (err) {
    log(`Transformation error`, err); //------------- log
  }
  log(`transformed initial data`, retObj); //---------------- log
  return retObj;
};

//----------------------------------------------------------
// Process flood score and map
//----------------------------------------------------------
const processFloodScore = (product, collector = {}) => {
  const scores = getProp(product, "FloodScores");
  const score = digObject(scores, "FloodScore", 0, "FloodScore");
  const url = digObject(scores, "FloodMap", 0, "Content", "ContentURL");

  if (!score && !url) {
    collector.FLOODSCORE_ERROR = "FLOODSCORE_ERROR";
    return collector;
  }

  collector.FLOOD_SCORE = score;
  collector.FLOOD_MAP = url;

  return collector;
};
