//------------------------------------------------------------
// check if an object is not null
//------------------------------------------------------------
export const isObject = (obj) => obj && typeof obj === "object";

//------------------------------------------------------------
// test if value is an array
//------------------------------------------------------------
export const isArray = (val) => Array.isArray(val);

//------------------------------------------------------------
// check function
//------------------------------------------------------------
export const isFunction = (func) => typeof func === "function";

//------------------------------------------------------------
// test for null or undefined values
//------------------------------------------------------------
export const isNone = (x) => x === null || x === undefined;

//------------------------------------------------------------
// test if value is a string
//------------------------------------------------------------
export const isString = (s) => typeof s === "string";

//------------------------------------------------------------
// test if value is a boolean
//------------------------------------------------------------
export const isBool = (x) => typeof x === "boolean";

//------------------------------------------------------------
// test if value is a valid number
//------------------------------------------------------------
export const isNumber = (n) => typeof n === "number" && isFinite(n);

//------------------------------------------------------------
// test if value is bad number (NaN or Infinity)
//------------------------------------------------------------
export const isBadNumber = (n) => typeof n === "number" && !isFinite(n);

//------------------------------------------------------------
// test if value is numeric (numeric string or finite number)
//------------------------------------------------------------
export const isNumeric = (n) => isFinite(n) && ![null, true, false, ""].includes(n);

//------------------------------------------------------------
// test if value is integer
//------------------------------------------------------------
export const isInt = (n) => isNumber(n) && n.toString().matches(/^-?\d+$/);

//------------------------------------------------------------
// round a number (convert into integer)
//------------------------------------------------------------
export const round = (x) => (isNumber(x) ? Math.round(x) : isNumeric(x) ? round(Number(x)) : x);

//------------------------------------------------------------
// test if array contains element
//------------------------------------------------------------
export const inArray = (arr, elem) => isArray(arr) && arr.includes(elem);

//------------------------------------------------------------
// check if property exists
//------------------------------------------------------------
//export const hasProp = (obj, name) => isObject(obj) && name in obj;

//------------------------------------------------------------
// check if all specified properties exist on object (and, optionally, not empty)
//------------------------------------------------------------
export const hasProp = (obj, ...names) => {
  if (!isObject(obj)) return false;
  for (let n of names) {
    if (!(n in obj)) return false;
  }
  return true;
};

//------------------------------------------------------------
// read property of an object, fail safe
//------------------------------------------------------------
export const getProp = (obj, name, fallback = null) => (hasProp(obj, name) ? obj[name] : fallback);

//------------------------------------------------------------
// read property of an object, ignoring keys' case
//------------------------------------------------------------
export const getPropNoCase = (obj, name, fallback = null) => {
  if (!isObject(obj) || !strlen(name)) return fallback;
  const search = name.toLowerCase();
  for (let k in obj) {
    if (k.toLocaleLowerCase() === search) return obj[k]; // first available, even if more keys fit
  }
  return fallback;
};

//------------------------------------------------------------
// extract listed properties from an object
//------------------------------------------------------------
export const getProps = (obj, ...props) => {
  if (!isObject(obj)) return null;
  const res = {};
  for (let p of props) {
    if (hasProp(obj, p)) res[p] = obj[p];
  }
  return res;
};

//------------------------------------------------------------
// find and return 1st available of listed properties from object
//------------------------------------------------------------
export const getAnyProp = (obj, ...keys) => {
  if (isObject(obj) && listSize(keys)) {
    for (let k of keys) {
      if (hasProp(obj, k)) return obj[k];
    }
  }
  return null;
};

//------------------------------------------------------------
// read property which is expected to be an object, if exists
//------------------------------------------------------------
export const getPropObject = (obj, name) => {
  const prop = getProp(obj, name);
  return isObject(prop) ? prop : null;
};

//------------------------------------------------------------
// set property on object, create a new object if none provided
//------------------------------------------------------------
export const setProp = (key, val, obj = null) => {
  const o = isObject(obj) ? obj : {};
  o[key] = val;
  return o;
};

//------------------------------------------------------------
// copy properties from one object to another, only if exist
//------------------------------------------------------------
export const copyProps = (keysArr, srcObj, destObj = {}) => {
  if (!isArray(keysArr) || !isObject(srcObj) || !isObject(destObj)) return null;
  for (let k of keysArr) {
    destObj[k] = getProp(srcObj, k); // null if no such key
  }
  return destObj;
};

//------------------------------------------------------------
// list object property keys, failsafe
//------------------------------------------------------------
export const objectKeys = (obj) => (isObject(obj) ? Object.keys(obj) : null);

//------------------------------------------------------------
// copy object with some keys renamed as per mapping
//------------------------------------------------------------
export const translateKeys = (srcObj, keymap) => {
  if (!isObject(srcObj)) return null;
  if (!isObject(keymap)) return { ...srcObj }; // shallow copy of original
  const destObj = {};
  for (let key in srcObj) {
    let newKey = getProp(keymap, key) || key;
    let val = srcObj[key];
    destObj[newKey] = val;
  }
  return destObj;
};

//------------------------------------------------------------
// copy single property from one object to another, only if exist
//------------------------------------------------------------
export const copyProp = (key, srcObj, destObj = {}) => copyProps([key], srcObj, destObj);

//------------------------------------------------------------
// compare values of the same props of two objects, return list of unmatched keys
//------------------------------------------------------------
export const diffProps = (obj1, obj2) => {
  const o1 = obj1 || {};
  const o2 = obj2 || {};
  const keys = [...new Set([...(objectKeys(o1) || []), ...(objectKeys(o2) || [])])];
  const diff = [];
  for (let k of keys) {
    if (getProp(o1, k) !== getProp(o2, k)) diff.push(k);
  }
  return diff;
};

//------------------------------------------------------------
// convert value to string, use empty string for 'null', 'false' and 'undefined'
//------------------------------------------------------------
export const strval = (x) => {
  if (isString(x)) return x;
  if (isNone(x) || x === false) return "";
  try {
    if (isNumber(x) || isObject(x) || isFunction(x)) return x.toString();
  } catch {
    console.log("cannot convert to string", x);
  }
  return `${x}`;
};

//------------------------------------------------------------
// test if x is a string, on success return its length
//------------------------------------------------------------
export const strlen = (x) => typeof x === "string" && x.length;

//------------------------------------------------------------
// test if x is an array, on success return its length
//------------------------------------------------------------
export const listSize = (x) => (Array.isArray(x) && x.length) || 0;

//------------------------------------------------------------
// tell if value is iterable
//------------------------------------------------------------
export const isIterable = (val) =>
  isArray(val) || isString(val) || (isObject(val) && typeof val[Symbol.iterator] === "function");

//------------------------------------------------------------
// convert iterable object to array, return fallback, if not iterable
//------------------------------------------------------------
export const asArray = (val, fallback = []) => (isIterable(val) ? [...val] : fallback);

//------------------------------------------------------------
// convert iterable object to sorted array
//------------------------------------------------------------
export const asSortedArray = (val) => asArray(val, []).sort();

//------------------------------------------------------------
// empty array (i.e. [])
//------------------------------------------------------------
export const isEmptyArray = (x) => Array.isArray(x) && !x.length;

//------------------------------------------------------------
// object has no enumerable own properties (i.e. {})
//------------------------------------------------------------
export const isEmptyObject = (x) => isObject(x) && !Object.keys(x).length;

//------------------------------------------------------------
// test for null, undefined, empty string, empty array or object
//------------------------------------------------------------
export const isEmpty = (x) =>
  isNone(x) || x === "" || isEmptyArray(x) || isEmptyObject(x) || isBadNumber(x);

//------------------------------------------------------------
// create new object from array of keys and same initial value
//------------------------------------------------------------
export const makeObject = (keys = null, initValue = null) => {
  const obj = {};
  const keysArr = strlen(keys)
    ? [keys]
    : isArray(keys)
    ? keys.map((k) => strval(k)).filter(Boolean)
    : [];
  if (!listSize(keysArr)) return obj;
  keysArr.forEach((k) => {
    obj[k] = initValue;
  });
  return obj;
};

//------------------------------------------------------------
// convert stringified boolean or any arbitrary value to boolean
//------------------------------------------------------------
export const bool = (x) => {
  if (x === true || x === false) return x;
  if (typeof x === "string") {
    let s = x.toLowerCase();
    if (s === "true") return true;
    if (s === "false") return false;
  }
  return !!x;
};

//------------------------------------------------------------
// copy a tree-like object structure stripping off empty properties
//------------------------------------------------------------
export const filterObject = (srcObj, keepEmptyString = false) => {
  const drop = (x) => {
    if (x === "" && keepEmptyString) return false;
    return isEmpty(x);
  };

  if (isArray(srcObj)) {
    let arr = srcObj.map((it) => filterObject(it, keepEmptyString)).filter((it) => !drop(it));
    return arr.length ? arr : null;
  }

  if (!isObject(srcObj)) return drop(srcObj) ? null : srcObj;

  let ret = {};
  for (let k in srcObj) {
    let val = filterObject(srcObj[k], keepEmptyString);
    if (!drop(val)) ret[k] = val;
  }
  return isEmptyObject(ret) ? null : ret;
};

//------------------------------------------------------------
// check if value equals to any of the others
//------------------------------------------------------------
export const equals = (val, ...others) => !!others.filter((it) => it === val).length;

//------------------------------------------------------------
// convert value to string in upper case
//------------------------------------------------------------
export const upperCase = (x) => strval(x).toUpperCase();

//------------------------------------------------------------
// convert value to string in lower case
//------------------------------------------------------------
export const lowerCase = (x) => strval(x).toLowerCase();

//------------------------------------------------------------
// capitalize words (optionally convert to lower case first)
//------------------------------------------------------------
export const capWords = (x, lowercase = true) => {
  if (!x || !isString(x)) return x;
  const s = lowercase ? x.toLocaleLowerCase() : x;
  const words = s
    .split(/\s/)
    .map((it) => (it.length ? it.charAt(0).toUpperCase() + it.substring(1) : ""));
  return words.join(" ");
};

//------------------------------------------------------------
// string s starts with prefix (optionally, case insensitive)
//------------------------------------------------------------
export const startsWith = (s, prefix, nocase = false) =>
  !!strlen(s) &&
  !!strlen(prefix) &&
  (nocase ? s.toLowerCase().startsWith(prefix.toLowerCase()) : s.startsWith(prefix));

//------------------------------------------------------------
// string s ends with suffix (optionally, case insensitive)
//------------------------------------------------------------
export const endsWith = (s, suffix, nocase = false) =>
  !!strlen(s) &&
  !!strlen(suffix) &&
  (nocase ? s.toLowerCase().endsWith(suffix.toLowerCase()) : s.endsWith(suffix));

//------------------------------------------------------------
// string includes substring (optionally, case insensitive)
//------------------------------------------------------------
export const stringIncludes = (s, ss, nocase = false) =>
  !!strlen(s) &&
  !!strlen(ss) &&
  (nocase ? s.toLowerCase().includes(ss.toLowerCase()) : s.includes(ss));

//------------------------------------------------------------
// prepend non-empty string with prefix, only if it's not there
//------------------------------------------------------------
export const addPrefix = (s, prefix) =>
  !!strlen(s) && !!strlen(prefix) && !s.startsWith(prefix) ? prefix + s : s;

//------------------------------------------------------------
// append suffix to non-empty string, only if it's not there
//------------------------------------------------------------
export const addSuffix = (s, suffix) =>
  !!strlen(s) && !!strlen(suffix) && !s.endsWith(suffix) ? s + suffix : s;

//------------------------------------------------------------
// un-capitalize first char (to deal with capitalized data keys)
//------------------------------------------------------------
export const uncap = (x) => (!!strlen(x) ? x.charAt(0).toLowerCase() + x.substring(1) : x);

// compare stringified values
export const sameString = (v1, v2) => strval(v1) === strval(v2);

//------------------------------------------------------------
// compare values ignoring strings' case
//------------------------------------------------------------
export const sameNoCase = (a, b) => lowerCase(a) === lowerCase(b);

//------------------------------------------------------------
// stringify as JSON safely
//------------------------------------------------------------
export const jsonStringify = (obj) => {
  try {
    return JSON.stringify(obj);
  } catch (x) {
    console.log(x);
  }
  return "";
};

//------------------------------------------------------------
// parse JSON string safely
//------------------------------------------------------------
export const jsonParse = (json, fallback = null) => {
  if (!strlen(json)) return fallback;
  try {
    return JSON.parse(json);
  } catch (x) {
    console.log(x);
  }
  return fallback;
};

//------------------------------------------------------------
// get serialized object's property from JSON string
//------------------------------------------------------------
export const getJsonProp = (json, name, fallback = null) =>
  getProp(jsonParse(json), name, fallback);

//------------------------------------------------------------
// remove duplicates from array
//------------------------------------------------------------
export const arrayUnique = (arr) => {
  if (isArray(arr)) return [...new Set(arr)];
  return arr;
};

//------------------------------------------------------------
// join array (just stringify if not array)
//------------------------------------------------------------
export const join = (arr, sep = ",") => (isArray(arr) ? arr.join(sep) : "");

//------------------------------------------------------------
// restore joined array from string
//------------------------------------------------------------
export const split = (s, sep = ",") => {
  return strlen(s) && isString(sep) ? s.split(sep) : [];
};

//------------------------------------------------------------
// restore joined array of numbers from string
//------------------------------------------------------------
export const splitNum = (s, sep = ",") => split(s, sep).map(Number);

//------------------------------------------------------------
// merge all objects into 1st one
//------------------------------------------------------------
export const mergeObjects = (...objects) => Object.assign(...objects.filter((it) => isObject(it)));

//------------------------------------------------------------
// merge objects serialized as JSON strings
//------------------------------------------------------------
export const mergeJson = (...entries) => {
  const objects = entries.map((it) => (isString(it) ? jsonParse(it) : it));
  const result = mergeObjects({}, ...objects);
  return jsonStringify(result);
};

//------------------------------------------------------------
// clone data object (or unserialize it from JSON)
//------------------------------------------------------------
export const cloneObject = (dataObj) => {
  const maybeJson = isString(dataObj);
  if (!isObject(dataObj) && !maybeJson) return null;
  try {
    const json = maybeJson ? dataObj : JSON.stringify(dataObj);
    return JSON.parse(json);
  } catch (x) {
    console.log(x);
  }
  return null;
};

//------------------------------------------------------------
// calculate average from a list of numbers
//------------------------------------------------------------
export const getAverage = (arrayOfNumbers) =>
  listSize(arrayOfNumbers) > 0 ? arrayOfNumbers.reduce((a, b) => a + b) / arrayOfNumbers.length : 0;

//------------------------------------------------------------
// trim string (safe)
//------------------------------------------------------------
export const trim = (x) => (isString(x) ? x.trim() : x);

//------------------------------------------------------------
// split string by non-word separators, remove empty entries
//------------------------------------------------------------
export const splitWords = (s) => (strlen(s) ? s.split(/\W+/).filter(Boolean) : []);

//------------------------------------------------------------
// join non-empty strings using a separator
//------------------------------------------------------------
export const joinWords = (separator, ...words) =>
  !listSize(words) ? "" : words.filter(Boolean).join(separator);

//------------------------------------------------------------
// escape string before passing to RegExp constructor
//------------------------------------------------------------
export const escReStr = (s) => s.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");

//------------------------------------------------------------
// range generator (generates array of consecutive numbers)
//------------------------------------------------------------
export const range = function* (start, end) {
  yield start;
  if (start === end) return;
  yield* range(start + 1, end);
};

//------------------------------------------------------------
// format number using locale
//------------------------------------------------------------
export const formatNumber = (num) => (isFinite(num) ? Number(num).toLocaleString() : "");

//------------------------------------------------------------
// format currency as dollars (no decimals)
//------------------------------------------------------------
export const formatDollars = (num, hideZero = false) => {
  let n = Number(num);
  if (isNaN(n) || (n === 0 && hideZero)) return "";
  let s = isFinite(n) ? n.toFixed(0) : "";
  if (s === "") return num + ""; // invalid number
  if (s.length > 3) {
    let buf = [];
    while (s.length > 3) {
      let pos = s.length - 3;
      buf.unshift(s.substring(pos));
      s = s.substring(0, pos);
    }
    buf.unshift(s);
    s = buf.join(",");
  }
  return "$" + s;
};

//------------------------------------------------------------
// traverse an object's tree for a property
//------------------------------------------------------------
export const digObject = (obj, ...keyList) => {
  if (!keyList.length) return obj;
  if (!isObject(obj)) return null;
  const keys = [...keyList];
  let k = keys.shift(),
    nextObj = getProp(obj, k, null);
  return digObject(nextObj, ...keys);
};

// same for JSON-serialized object
export const digJson = (objAsJson, ...keyList) => {
  const obj = jsonParse(objAsJson);
  return digObject(obj, ...keyList);
};

//------------------------------------------------------------
// find a (nested) property in object's hierarchy (1st available instance)
//------------------------------------------------------------
export const getTreeProp = (obj, propName) => {
  if (!isObject(obj)) return null;
  if (isArray(obj)) {
    if (isFinite(propName)) {
      let n = Number(propName);
      if (n < obj.length) return obj[n];
    }
    for (let item of obj) {
      let val = getTreeProp(item, propName);
      if (val !== null) return val;
    }
    return null;
  }
  if (propName in obj) return obj[propName];
  for (let k in obj) {
    let val = getTreeProp(obj[k], propName);
    if (val !== null) return val;
  }
  return null;
};

//------------------------------------------------------------
// search entire tree for data keys (1st available)
// translate mapped keys, provided as {keyToUse: keyToSearch}
//------------------------------------------------------------
export const searchTree = (obj, keyList, keyMap = null) => {
  if (!obj) return null;
  const result = {};
  if (!listSize(keyList)) return result;
  keyList.forEach((key) => {
    let searchKey = getProp(keyMap, key) || key;
    let val = getTreeProp(obj, searchKey);
    if (!isNone(val)) result[key] = val;
  });
  return result;
};

//------------------------------------------------------------
// search tree and populate collector object
//------------------------------------------------------------
export const loadFromTree = (obj, keyList, keyMap = null, collector = {}) => {
  const result = searchTree(obj, keyList, keyMap);
  Object.assign(collector, result);
  return collector;
};

//------------------------------------------------------------
// un-capitalize data keys (creates a copy of object)
//------------------------------------------------------------
export const uncapKeys = (obj) => {
  if (!isObject(obj)) return obj;
  const ret = {};
  Object.keys(obj).forEach((k) => (ret[uncap(k)] = obj[k]));
  return ret;
};

//------------------------------------------------------------
// sort data keys (creates a copy of object)
//------------------------------------------------------------
export const sortKeys = (obj) => {
  if (!isObject(obj)) return obj;
  const ret = {};
  Object.keys(obj)
    .sort()
    .forEach((k) => (ret[k] = obj[k]));
  return ret;
};

//------------------------------------------------------------
// debounce wrapper
//------------------------------------------------------------
export const debounce = (func, wait, immediate) => {
  let timeout;
  return (...args) => {
    const execLater = () => {
      timeout = null;
      if (!immediate) func(...args);
    };
    const doNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(execLater, wait);
    if (doNow) func(...args);
  };
};

//------------------------------------------------------------
// generate unique request ID
//------------------------------------------------------------
export const makeNumericId = () => Math.round(10 ** 12 * Math.random()).toString();

//------------------------------------------------------------
// produce UTC date+time stamp in format '2022-05-03 13:17:31'
//------------------------------------------------------------
export const makeShortDateTimeStamp = (dateObj = null) => {
  const d = dateObj ? new Date(dateObj) : new Date(); // numeric timestamp is accepted
  d.setMinutes(d.getMinutes() - d.getTimezoneOffset()); // adjust output to local time
  return d
    .toISOString()
    .replace(/\.\S+$/, "")
    .replace("T", " ");
};

//------------------------------------------------------------
// produce UTC date stamp in format '2022-05-03'
//------------------------------------------------------------
export const makeShortDateStamp = (dateObj = null) => makeShortDateTimeStamp(dateObj).split(" ")[0];

//------------------------------------------------------------
// Parse date stamp in format '2022-05-03'
//------------------------------------------------------------
export const parseShortDateStamp = (dateStr) => {
  const m = strval(dateStr).match(/\d{4}-\d{2}-\d{2}/);
  if (!m) return 0;
  return new Date(m[0] + "T12:00").getTime();
};

//------------------------------------------------------------
// Transform short date string from '2022-05-03' to 'May 3, 2022'
//------------------------------------------------------------
export const transformShortDateFormat = (dateStr, lang = undefined) => {
  const ts = parseShortDateStamp(dateStr);
  return !ts
    ? ""
    : new Date(ts).toLocaleDateString(lang, {
        year: "numeric",
        month: "short",
        day: "numeric",
      });
};

// parseShortDateStamp(dataPoints[items[0].dataIndex].Date).toLocaleDateString()

//------------------------------------------------------------
// add space in postal code
//------------------------------------------------------------
export const separatePostal = (addr) =>
  isString(addr) ? addr.trim().replace(/\b([A-Z]\d[A-Z])(\d[A-Z]\d)$/g, "$1 $2") : addr;

//------------------------------------------------------------
// remove space in postal code
//------------------------------------------------------------
export const fixPostal = (addr) =>
  isString(addr) ? addr.trim().replace(/\b([A-Z]\d[A-Z])\s+(\d[A-Z]\d)$/g, "$1$2") : addr;

//------------------------------------------------------------
// check if something looks like postal code:
// letters D, F, I, O, Q, U are never used
//------------------------------------------------------------
export const isPostal = (s) =>
  isString(s) && /^[A-Z]\d[A-Z]\s?\d[A-Z]\d$/i.test(s) && s.search(/[dfioqu]/i) === -1;

//------------------------------------------------------------
// Add parameter to PDF report URL to show progress page
// until PDF is ready
//------------------------------------------------------------
export const decoratePdfUrl = (url) => (startsWith(url, "http") ? url + "?progress=y" : ".");

//------------------------------------------------------------
// produce CSS class name from any string value
// (empty string on failure)
//------------------------------------------------------------
export const stringToCssClass = (value) =>
  value && isString(value)
    ? value
        .toLowerCase()
        .replace(/\s.*$/, "")
        .replace(/[^a-z\s]+/gi, "")
    : "";
