import React, { useState, useEffect, useCallback } from "react";
import { useGlobal } from "reactn";
import storage, { LOGINFAILKEY } from "../../lib/localstorage";
import { login } from "../../lib/session";
import useLanguage from "../useLanguage";
import VeriskBanner from "./VeriskBanner";
import getLogger from "../../lib/debug-logger";

import "../../styles/avm/sign-in.scss";

const log = getLogger("SignIn", 1);

const loginBlockTime = 1000 * 600;

const [loginBlocked, failCountPlus] = (() => {
  const skey = LOGINFAILKEY;
  const sval = storage.get(skey);
  let cnt = Number(sval) || 0;
  const blocked = cnt > Date.now();

  log(`login track:`, { sval, cnt, blocked }); //------------------ log

  if (!blocked && cnt) {
    log(`removing login count`, cnt); //---------------- log
    storage.remove(skey);
  }

  const addCount = () => {
    if (cnt < 3) cnt += 1;
    else cnt = Date.now() + loginBlockTime; // blocking 3-times failed login for 'loginBlockTime'
    log(`updating storage`, { [skey]: cnt }); //----------------------- log
    storage.set(skey, cnt);
  };

  return [blocked, addCount];
})();

const SignIn = (props) => {
  const { t } = useLanguage();

  const [busy] = useGlobal("BUSY");
  const [refreshing] = useGlobal("REFRESHING");
  const [userInfo] = useGlobal("USER_INFO");
  const [error] = useGlobal("ERROR");
  const [signingOut] = useGlobal("SIGNING_OUT");

  const [user, setUser] = useState("");
  const [pass, setPass] = useState("");

  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (error) failCountPlus();
  }, [error]);

  // disable submit?
  useEffect(() => {
    setDisabled(loginBlocked || !!busy || !!signingOut || user === "" || pass === "");
  }, [busy, signingOut, user, pass]);

  const handleSubmit = useCallback(
    (ev) => {
      if (loginBlocked) return;
      ev.preventDefault();
      login(user, pass);
    },
    [user, pass]
  );

  const handleInputChange = (ev) => {
    const { name, value } = ev.target;
    if (name === "user") setUser(value);
    else if (name === "pass") setPass(value);
  };

  if (!!userInfo || refreshing) return null;

  return (
    <React.Fragment>
      <div className="fixed-form-wrap sign-in">
        <h2 className="intro-title">{t("Login")}</h2>

        <form onSubmit={handleSubmit}>
          <fieldset>
            <div className="form-section">
              <label>{t("Username")}</label>
              <input
                className="text-input username-input"
                type="text"
                autoFocus
                key="username"
                name="user"
                placeholder={t("Enter your username")}
                disabled={loginBlocked || busy || signingOut}
                autoComplete="off"
                onChange={handleInputChange}
              />
            </div>
            <div className="form-section">
              <label>{t("Password")}</label>
              <input
                className="text-input password-input"
                type="password"
                key="password"
                name="pass"
                placeholder={t("EnterYourPassword")}
                disabled={loginBlocked || busy || signingOut}
                autoComplete="off"
                onChange={handleInputChange}
              />
            </div>
            <div className="form-section">
              <a className="link forgot-password-link" href="/forgot-password.html">
                {t("Forgot Password")}
              </a>
            </div>
            <div className="form-section submit-box">
              <button className="big-button sign-in-btn" type="submit" disabled={disabled}>
                {t("Enter")}
              </button>
            </div>
            {!!error && !!signingOut && (
              <div className="error-message blink-1s">{t("Error401")}</div>
            )}
          </fieldset>
        </form>
        <VeriskBanner />
      </div>
    </React.Fragment>
  );
};

export default SignIn;
