import React from "react";
import cx from "classnames";
import GoogleStreetView from "./GoogleStreetView";
import withModal from "../withModal";

const ModalStreetview = (props) => {
  const { className, cleanup } = props;
  const cls = cx("modal-box", className);

  return (
    <div className={cls}>
      <div className="modal-content">
        <GoogleStreetView />
        <div className="bottom">
          <button className="btn-close" onClick={() => cleanup()}>
            {"Close"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default withModal(ModalStreetview);
