import React, { useCallback, useEffect, useState } from "react";
import { useGlobal } from "reactn";
import cx from "classnames";
import useLanguage from "../useLanguage";
import { addSuffix } from "../../lib/util";

import "../../styles/avm/avm-layout.scss";

// page navigation handler
const navigateToPage = (filename) => {
  window.location.href = !filename || filename === "/" ? "/" : addSuffix(filename, ".html");
};

const AvmLayout = (props) => {
  // construction features JSON snapshot (critical error, if not produced from prefill call)
  const [prefillSnapshot] = useGlobal("FEATURES_CURRENT");

  // language meant to be used as CSS class selector
  const [lang] = useGlobal("LANGUAGE");

  // street address must be empty on Advanced Search, used here as a flag
  const [addressLine] = useGlobal("ADDRESS_LINE");

  const [pageName] = useGlobal("PAGE_NAME");
  const [hasComparables] = useGlobal("PROD_AVAIL_COMPARABLES");
  const [hasListings] = useGlobal("PROD_AVAIL_LISTING");
  const [hasSales] = useGlobal("PROD_AVAIL_SALES");
  const [hasAssessments] = useGlobal("PROD_AVAIL_ASSESSMENTS");
  const [hasRentals] = useGlobal("PROD_AVAIL_RENTALS");
  const [hasMetrics] = useGlobal("PROD_AVAIL_METRICS");

  // Environmental show flag -- to be wired with actual products
  const [hasFlood] = useGlobal("PROD_AVAIL_FLOOD");
  const [hasFire] = useGlobal("PROD_AVAIL_WILDFIRE");
  const [hasFus] = useGlobal("PROD_AVAIL_FUS");

  const [hasEnv, setHasEnv] = useState(false);
  
  const [collapsed, setCollapsed] = useState(false); // reserved for "expanding"
  const [navDisabled, setNavDisabled] = useState(true); // tab click navigation is disabled

  // language translation function
  const { t } = useLanguage();

  // aggregate environmental products
  useEffect(() => {
    const test = hasFlood || hasFire || hasFus || false;
    setHasEnv(test);
  }, [hasFlood, hasFire, hasFus, setHasEnv]);

  // disable navigation if property construction features data is not available
  useEffect(() => {
    setNavDisabled(!prefillSnapshot);
  }, [prefillSnapshot]);

  const toggleExpand = () => {
    setCollapsed(!collapsed);
  };

  const handleTabClick = useCallback(
    (page) => {
      if (navDisabled || page === pageName) return;
      const loc = page === "main" ? "/" : page;
      navigateToPage(loc);
    },
    [navDisabled, pageName]
  );

  //-------------------------------------------------------------------------------------
  //if (error) return <PropertyError title="Error" message={error} />;
  //-------------------------------------------------------------------------------------

  return (
    <div
      className={cx("avm-layout", lang, "table", {
        collapsed,
      })}
    >
      <div className="table-row">
        <div className={cx("avm-layout-side table-cell", { collapsed })}>
          <div className={cx("avm-layout-nav", { collapsed })}>
            <div
              role="button"
              className={cx("nav-item", {
                current: pageName === "main",
                disabled: navDisabled,
              })}
              onClick={() => {
                handleTabClick("/");
              }}
            >
              <span className="item-icon background-property-search"></span>
              <span className="item-text">{t("Property Details")}</span>
            </div>

            {!!(hasListings || hasSales || hasAssessments || hasRentals) && (
              <div
                role="button"
                className={cx("nav-item", {
                  current: pageName === "valuations",
                  disabled: navDisabled,
                })}
                onClick={() => {
                  handleTabClick("valuations");
                }}
              >
                <span className="item-icon bg-icon-valuation"></span>
                <span className="item-text">{t("Valuation") + " +"}</span>
              </div>
            )}

            {!!hasMetrics && (
              <div
                role="button"
                className={cx("nav-item", {
                  current: pageName === "metrics",
                  disabled: navDisabled,
                })}
                onClick={() => {
                  handleTabClick("metrics");
                }}
              >
                <span className="item-icon bg-icon-metrics"></span>
                <span className="item-text">{t("Market Metrics")}</span>
              </div>
            )}

            {!!hasComparables && !!addressLine && (
              <div
                role="button"
                className={cx("nav-item", {
                  current: pageName === "comparables",
                  disabled: navDisabled,
                })}
                onClick={() => {
                  handleTabClick("comparables");
                }}
              >
                <span className="item-icon bg-icon-comparables"></span>
                <span className="item-text">{t("Comparables")}</span>
              </div>
            )}

            {!!hasEnv && (
              <div
                role="button"
                className={cx("nav-item", {
                  current: pageName === "environmental",
                  disabled: navDisabled,
                })}
                onClick={() => {
                  handleTabClick("environmental");
                }}
              >
                <span className="item-icon bg-icon-environ"></span>
                <span className="item-text">{t("Environmental")}</span>
              </div>
            )}
          </div>
        </div>
        <div
          role="button"
          className={cx("avm-layout-toggle table-cell", { collapsed })}
          onClick={toggleExpand}
        ></div>
        <div className="content-wrapper table-cell">
          <div className="mainview">{props.children}</div>
        </div>
      </div>
    </div>
  );
};

export default AvmLayout;
