import React, { useState, useEffect } from "react";
import { useGlobal } from "reactn";
import cx from "classnames";
import useLanguage from "../useLanguage";

import { decoratePdfUrl, startsWith } from "../../lib/util";
import { updateStoredValue } from "../../lib/local-data";

import getLogger from "../../lib/debug-logger";

const log = getLogger("ReportButton", 3);

// report button can be forceably disabled w/o further API calls by assigning any string value
// not beginning with 'http'

const ReportButton = () => {
  //
  const [reportUrl] = useGlobal("REPORT_URL");

  const [unsaved] = useGlobal("SAVE_ENABLED");

  const [disabled, setDisabled] = useState(false);

  const { t, language } = useLanguage();

  useEffect(() => {
    // store report to use on other pages
    log(`storing Report URL`, reportUrl); //---------------- log
    updateStoredValue("REPORT_URL", reportUrl || "");
  }, [reportUrl]);

  // toggle button's disabled status
  useEffect(() => {
    const test = !!unsaved || !reportUrl || !startsWith(reportUrl, "http");
    setDisabled(test);
  }, [unsaved, reportUrl]);

  const handleClick = (e) => {
    if (disabled) e.preventDefault();
  };

  return (
    <a
      className={cx("report-btn", "big-button", language, {
        disabled,
      })}
      href={decoratePdfUrl(reportUrl)}
      onClick={handleClick}
      rel="noopener noreferrer"
      target="_blank"
    >
      {t("Report")}
    </a>
  );
};

export default ReportButton;
