import React from "react";
import cx from "classnames";

import useLanguage from "../useLanguage";

// empty image placeholder
const PropertyImagePlaceholder = (props) => {
  const { loading, error, isMap } = props;
  const finished = !loading || error;
  const viewType = isMap ? "Map" : "Street View";

  const { language, FR } = useLanguage();
  const availabilityText =
    language === FR ? "n’est pas disponible" : "is not available";
  const loadingText = language === FR ? "chargement" : "loading";
  const text = finished
    ? `${viewType} ${availabilityText}`
    : `${loadingText}...`;
  const cls = finished ? "image-missing" : "shimmer-gradient";

  // "No Street View" image
  if (!isMap && finished)
    return (
      <div
        className={cx("no-street-view", { fr: language === FR })}
        style={{ height: "220px" }}
      ></div>
    );

  return (
    <div className={cx("image-placeholder", cls)}>
      <p>{text}</p>
    </div>
  );
};

export default PropertyImagePlaceholder;
