import storage from "./localstorage";
import {
  copyProps,
  filterObject,
  getAnyProp,
  getProp,
  isArray,
  isNone,
  isObject,
  jsonParse,
  listSize,
} from "./util";
import { getPropertyStateKeys } from "./globals";
import { getGlobal, setGlobal, setGlobalValue } from "./global-util";
import getLogger from "./debug-logger";

const log = getLogger("local-data", 2);

//------------------------------------------------------
// Localstorage data utility functions
//------------------------------------------------------

const dataTTL = 1000 * 60 * 60 * 3;

//------------------------------------------------------
// store/retrieve last property address for reset purpose
//------------------------------------------------------
export const storeInitAddress = (addrLine) => {
  storage.set("init_address", addrLine);
};

export const popInitAddress = () => storage.pop("init_address");

//------------------------------------------------------
// check if last property data in localstorage exists
//------------------------------------------------------
export const storedPropertyDataExists = () => !!getPropertyInfo();

//------------------------------------------------------
// write property data into localstorage
//------------------------------------------------------
const setPropertyInfo = (obj) => {
  if (!isObject(obj)) {
    log(`object expected. Actual value:`, obj); //---------------- log
    return;
  }
  if (!getProp(obj, "DATA_EXPIRES")) {
    obj.DATA_EXPIRES = Date.now() + dataTTL;
  }
  storage.set("propertyInfo", obj);
};

//------------------------------------------------------
// delete property data from localstorage
//------------------------------------------------------
export const removePropertyInfo = () => {
  log(`removing property info from locastorage`); //--------- log
  storage.remove("propertyInfo");
};

//------------------------------------------------------
// read property data from localstorage
//------------------------------------------------------
export const getPropertyInfo = () => {
  const obj = storage.get("propertyInfo");
  if (!isObject(obj)) {
    log(`no stored property data`); //--------- log
    return null;
  }
  log(`stored property address:`, obj.ADDRESS_LINE); //-------- log
  if (!getProp(obj, "FEATURES_CURRENT")) {
    log(`stored property data is corrupted`); //--------- log
    return null;
  }
  const expTime = Number(getProp(obj, "DATA_EXPIRES"));
  if (!expTime || expTime < Date.now()) {
    log(`stored property data has expired`); //--------- log
    removePropertyInfo();
    return null;
  }
  return obj;
};

//------------------------------------------------------
// collect current property data and put it in localstopage
//------------------------------------------------------
export const storePropertyInfo = (dataObj = null) => {
  const obj = dataObj || getGlobal();
  const keys = [...getPropertyStateKeys()];
  const propertyInfo = filterObject(copyProps(keys, obj));
  log(`saving property info in localstorage. Keys:`, Object.keys(propertyInfo).sort()); //--------- log
  setPropertyInfo(propertyInfo);
};

//------------------------------------------------------
// update stored property data and save it in localstopage
//------------------------------------------------------
const updatePropertyInfoObj = (obj) => {
  if (!isObject(obj)) {
    log(`object expected, actual value is`, obj); //-------------- log
    return;
  }
  log(`preparing updates`, obj); //---------------- log
  const info = getPropertyInfo();
  if (!isObject(info)) {
    return;
  }
  const updated = filterObject({ ...info, ...obj });
  setPropertyInfo(updated);
};

//------------------------------------------------------
// update stored property data by global state keys
// or as an object and save it in localstopage
//------------------------------------------------------
export const updatePropertyInfo = (globalKeylist = null) => {
  if (isObject(globalKeylist)) {
    return updatePropertyInfoObj(globalKeylist);
  }
  const keys = isArray(globalKeylist)
    ? [...globalKeylist]
    : isNone(globalKeylist)
    ? getPropertyStateKeys()
    : null;
  if (!listSize(keys)) {
    log(`list of global state keys is expected`); //---------- log
    return null;
  }
  const obj = copyProps(keys, getGlobal());
  return updatePropertyInfoObj(obj);
};

//------------------------------------------------------
// update current data snapshot by single data point
//------------------------------------------------------
export const updateStoredValue = (key, value) => {
  updatePropertyInfoObj({ [key]: value });
};

//------------------------------------------------------
// update stored data in sync with globals
//------------------------------------------------------
export const setAndStoreGlobals = (obj) => {
  if (isObject(obj)) {
    updatePropertyInfoObj(obj);
    setGlobal(obj);
  } else {
    log("setAndStoreGlobals: object expected. Found:", obj);
  }
};

//------------------------------------------------------
// update current data snapshot and global object by single data point
//------------------------------------------------------
export const setAndStoreGlobalValue = (key, val) => {
  updateStoredValue(key, val);
  setGlobalValue(key, val);
};

//------------------------------------------------------
// restore last saved property data and update global object
//------------------------------------------------------
export const restorePropertyInfo = () => {
  const storedInfo = getPropertyInfo();
  if (!storedInfo) {
    log(`no stored property data found`); //------------ log
    return false;
  }
  const updates = { ...storedInfo, ...jsonParse(storedInfo.FEATURES_CURRENT) };

  log(`restoring property info from localstorage`, updates); //--------- log
  updates.PROPERTY_SOURCE = "cache";
  setGlobal(updates);

  return true;
};

//------------------------------------------------------
// check if valid property data exists in localstorage
//------------------------------------------------------
export const propertyInfoExists = () => !!getPropertyInfo();

//------------------------------------------------------
// invalidate PDF report and Property Snapshot PDF URLs - global and stored
//------------------------------------------------------
export const dropReport = () => {
  const upd = { REPORT_URL: null, REPORT_COMP_URL: null, SNAPSHOT_URL: null };
  setGlobal(upd);
  updatePropertyInfoObj(upd);
};

//--------------------------------------------------------------
// committing changes in property stats
//--------------------------------------------------------------
export const saveUpdatedStats = () => {
  const glob = getGlobal();
  const statsJson = getProp(glob, "STATS_CURRENT");
  const prevStatsJson = getAnyProp(glob, "STATS_INITIAL", "STATS_UPDATED");
  if (statsJson && statsJson !== prevStatsJson) {
    log(`updating property stats`, jsonParse(statsJson)); //------------- log
    setAndStoreGlobalValue("STATS_UPDATED", statsJson);
  }
};
