// hidden in Verisk branding

import React from "react";

const copyYear = new Date().getFullYear();

const PageFooter = () => {
  return <footer className="page-footer opta">{`©${copyYear} Opta Information Intelligence`}</footer>;
};

export default PageFooter;
