import React, { useCallback, useEffect, useState } from "react";
import { useGlobal } from "reactn";
import cx from "classnames";
import useLanguage from "../useLanguage";
import { addPropertyReview } from "../../lib/overlord-api";
import getLogger from "../../lib/debug-logger";

/*
Property construction features' Overlord button
*/

const log = getLogger("PropertyOverlordButton", 4);

// call API and render result
const callApi = async (opts = {}) => {
  const result = await addPropertyReview(opts);
  log("API response data", result); //------------------------- log
};

const PropertyOverlordButton = (props) => {
  const [overlordEnabled] = useGlobal("OVERLORD_ENABLED");
  const [lastFeatsSnapshot] = useGlobal("FEATURES_CURRENT");

  const { t, language } = useLanguage();

  const [clicked, setClicked] = useState(false);

  const [buttonEnabled, setButtonEnabled] = useState(false);

  useEffect(() => {
    const b = overlordEnabled && !clicked;
    log(`Overlord button ${b ? "en" : "dis"}abled`); //------------------------- log
    setButtonEnabled(b);
  }, [overlordEnabled, clicked]);

  const handleSubmit = useCallback(
    (ev) => {
      if (!overlordEnabled) {
        log("Review/Overlord disabled"); //------------------------- log
        return;
      }
      log("submitting updates for Review/Overlord"); //------------------------- log
      callApi({ Status: "Completed" });
      setClicked(true);
      setTimeout(() => {
        setClicked(false);
      }, 2000);
    },
    [overlordEnabled]
  );

  //--------------------------------------------------------------
  // if construction feats not available, don't render this button
  if (!lastFeatsSnapshot) return null;
  //--------------------------------------------------------------

  return (
    <button className={cx("big-button", "overlord-button", language)} onClick={handleSubmit} disabled={!buttonEnabled}>
      {t("Overlord")}
    </button>
  );
};

export default PropertyOverlordButton;
